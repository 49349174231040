import React from "react"

const ActivityOption = props => (
  <div className="input-with-remove-button">
    <div className="input-container">
      <input
        className="string render form-control"
        value={props.value}
        name={props.name}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </div>

    <a
      onClick={props.onRemove}
      className={"btn btn-link pull-right " + props.disabledLinkClass}
    >
      &times;
    </a>
  </div>
)

export default ActivityOption
