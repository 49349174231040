import React from 'react'

import SelectedPrograms from '../training_plan/SelectedPrograms'
import { setElementDisabled } from '../utils'

const ProgramSelect = ({ availablePrograms, availableTemplates }) => {
  const [selectedProgram, setSelectedProgram] = React.useState(null)
  const [selectedProgramsList, setSelectedProgramsList] = React.useState([])
  const [selectedGlobalProgram, setSelectedGlobalProgram] = React.useState(null)
  const [programType, setProgramType] = React.useState('global_program')

  const selectedPrograms = selectedGlobalProgram?.queue_template_programs?.map(
    ({ program: { name, program_activities }, repetitions }) => ({
      name,
      program_activities,
      repetitions
    })
  )

  const coreActivities = (activities) =>
    activities.filter(
      ({ program_activity_type }) => program_activity_type === 'core'
    )

  const swapActivities = (activities) =>
    activities.filter(
      ({ program_activity_type }) => program_activity_type === 'swap'
    )

  const renderActivity = ({ activity: { name } }, index) =>
    `${index + 1}: ${name}`

  const renderActivities = (activities) => {
    const swaps = swapActivities(activities)

    return (
      coreActivities(activities).map(renderActivity).join('\n') +
      (swaps.length > 0 ? '\n---\n' : '') +
      swaps?.map(renderActivity).join('\n')
    )
  }

  const renderProgramOption = (program) => (
    <option key={program.id} value={program.id}>
      {program.name}
    </option>
  )

  const renderTemplateOption = (template) => (
    <option key={template.id} value={template.id}>
      {template.name}
    </option>
  )

  const renderSelectedActivitiesFromSelectedProgram = () => (
    <dl className="" id="activity_details">
      <dt>Core Activities</dt>
      {selectedProgram.program_activities
        .filter(({ program_activity_type }) => program_activity_type === 'core')
        .map(({ activity: { name } }, index) => (
          <div key={index} className="row" title="">
            <div className="col-sm-12">{name}</div>
          </div>
        ))}
    </dl>
  )

  const renderSelectedSwapActivitiesFromSelectedProgram = () => {
    const swapActivities = selectedProgram.program_activities.filter(
      ({ program_activity_type }) => program_activity_type === 'swap'
    )

    if (swapActivities.length === 0) return null

    return (
      <dl className="" id="activity_details">
        <dt>Swap Activities</dt>
        {swapActivities.map(({ activity: { name } }, index) => (
          <div key={index} className="row" title="">
            <div className="col-sm-12">{name}</div>
          </div>
        ))}
      </dl>
    )
  }

  const renderSelectedProgramsFromGlobalProgram = () =>
    selectedPrograms.map(({ name, program_activities, repetitions }, index) => (
      <div
        key={index}
        className="row"
        title={renderActivities(program_activities)}
        style={{ cursor: 'help' }}
      >
        <div className="col-md-12">
          <span style={{ fontStyle: 'italic' }}>
            {repetitions} repetition{repetitions > 1 ? 's: ' : ':  '}
          </span>
          {name}
        </div>
      </div>
    ))

  const renderGlobalProgramSelector = () => {
    if (programType !== 'global_program') return null

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="form-group string required queue_template_name form-group-valid">
            <label
              htmlFor="queue_template_id"
              className="form-control-label string required"
            >
              Global Program<span> </span>
              <abbr title="required">*</abbr>
            </label>
            <select
              id="queue_template_id"
              name="queue_template_id"
              className="form-control is-valid string required"
              value={selectedGlobalProgram?.id || ''}
              onChange={handleGlobalProgramChange}
            >
              <option value="" disabled>
                Select Global Program
              </option>
              {availableTemplates.map(renderTemplateOption)}
            </select>
          </div>
        </div>
        {selectedGlobalProgram && (
          <div className="col-md-12">
            <dl className="" id="queue_template_details">
              <dt>Description</dt>
              <dd>{selectedGlobalProgram.description}</dd>
            </dl>
          </div>
        )}
      </div>
    )
  }
  const renderProgramSelector = () => {
    if (programType !== 'program') return null

    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group string required program_name form-group-valid">
              <label
                htmlFor="program_id"
                className="form-control-label string required"
              >
                Individual Programs
              </label>
              <select
                id="program_id"
                name="program_id"
                className="form-control is-valid string required"
                onChange={handleProgramChange}
                value={selectedProgram?.id || ''}
              >
                <option value="" disabled>
                  Select Program
                </option>
                {availablePrograms.map(renderProgramOption)}
              </select>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginBottom: '14px' }}>
          <div className="col-sm-12">
            <button
              className="btn btn-primary pull-left"
              disabled={selectedProgram ? false : true}
              onClick={handleAddSelectedProgramToSelectedList}
              type="button"
            >
              Select Program
            </button>
          </div>
        </div>
      </>
    )
  }

  const toggleSubmitButton = (list = []) => {
    if (list.length === 0) {
      setElementDisabled('button.submit-button', true)
    } else {
      setElementDisabled('button.submit-button', false)
    }
  }

  const handleAddSelectedProgramToSelectedList = () => {
    const newList = [...selectedProgramsList, selectedProgram]

    setSelectedProgramsList(newList)
    toggleSubmitButton(newList)
  }

  const handleRemoveProgramFromSelectedList = (program) => {
    const index = selectedProgramsList.indexOf(program)

    if (index > -1) {
      const programs = [].concat(selectedProgramsList)
      programs.splice(index, 1)
      setSelectedProgramsList(programs)
      toggleSubmitButton(programs)
    }
  }

  const handleGlobalProgramChange = ({ currentTarget: { value } }) => {
    const targetGlobalProgram = availableTemplates.find((t) => t.id == value)

    setSelectedGlobalProgram(targetGlobalProgram)
    toggleSubmitButton([targetGlobalProgram])
  }

  const handleProgramTypeChange = ({ currentTarget: { value } }) => {
    setProgramType(value)

    if (value === 'global_program') {
      toggleSubmitButton([selectedGlobalProgram])
    } else {
      toggleSubmitButton(selectedProgramsList)
    }
  }
  const handleProgramChange = ({ currentTarget: { value } }) => {
    const targetProgram = availablePrograms.find((t) => t.id == value)

    setSelectedProgram(targetProgram)
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="well" style={{ minHeight: '250px' }}>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group string required queue_template_name form-group-valid">
                  <label
                    htmlFor="assignment_type"
                    className="form-control-label string required"
                  >
                    Program Type <span> </span>
                    <abbr title="required">*</abbr>
                  </label>
                  <select
                    id="assignment_type"
                    name="assignment_type"
                    className="form-control is-valid string required"
                    onChange={handleProgramTypeChange}
                    value={programType}
                  >
                    <option value="global_program">Global Program</option>
                    <option value="program">Individual Programs</option>
                  </select>
                </div>
              </div>
            </div>
            {renderGlobalProgramSelector()}
            {renderProgramSelector()}
          </div>
          {programType === 'global_program' && selectedGlobalProgram && (
            <div className="col-md-6">
              <dl className="" id="queue_template_details">
                <dt>Programs</dt>
                {renderSelectedProgramsFromGlobalProgram()}
              </dl>
            </div>
          )}
          {programType === 'program' && selectedProgram && (
            <div className="col-md-6">
              <dl className="" id="program_details">
                <dt>Description</dt>
                <dd>{selectedProgram.description}</dd>
              </dl>
              <div className="row">
                <div className="col-sm-6">
                  {renderSelectedActivitiesFromSelectedProgram()}
                </div>
                <div className="col-sm-6">
                  {renderSelectedSwapActivitiesFromSelectedProgram()}
                </div>
              </div>
            </div>
          )}
          {programType === 'program' && (
            <div className="col-md-12">
              <h4 className="sub-title" style={{ display: 'inline-block' }}>
                Selected Programs
              </h4>
              <SelectedPrograms
                data={selectedProgramsList}
                hideSchools={true}
                onRemoveProgram={handleRemoveProgramFromSelectedList}
              />
            </div>
          )}
          {programType === 'program' && selectedProgramsList.length === 0 && (
            <div className="col-md-12">
              <div className="alert alert-info">
                No programs selected. In order to assign programs to students
                please select at least one program.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProgramSelect
