import React from "react"
import { Emoji, Text, String, Select } from "../form/inputs"

const QuestionInput = props => {
  const inputId = "questionsInput" + props.index
  const emojiInput = () => (
    <Emoji
      label={props.question.question}
      inputId={inputId}
      name={props.question.question}
      options={props.question.options || []}
    />
  )
  const textInput = () => (
    <Text
      label={props.question.question}
      inputId={inputId}
      name={props.question.question}
    />
  )
  const stringInput = () => (
    <String
      label={props.question.question}
      inputId={inputId}
      name={props.question.question}
    />
  )
  const multiSelectInput = () => selectInput({ multiple: true })
  const selectInput = options => (
    <Select
      {...options}
      label={props.question.question}
      options={props.question.options || []}
      inputId={inputId}
      name={props.question.question}
    />
  )

  if (props.question.type === "emoji") return emojiInput()
  if (props.question.type === "text") return textInput()
  if (props.question.type === "string") return stringInput()
  if (props.question.type === "multi_select") return multiSelectInput()
  if (props.question.type === "select") return selectInput()
  return stringInput() //default
}

export default QuestionInput
