import React from 'react'

const SelectList = props => {
  const { collection, preselected_tests, cnsvs_tests_errors, locked } = props

  const [selected, setSelected] = React.useState(
    preselected_tests ? preselected_tests.map(code => collection.find(e => e['code'] === code)) : []
  )
  const [currentCollection, setCurrentCollection] = React.useState(
    preselected_tests ? _.sortBy(collection.filter(i => !preselected_tests.includes(i['code'])), (item) => {return item['id']}) : collection
  )

  const addSelection = item => {
    setSelected([...selected, item])
    setCurrentCollection( _.sortBy(currentCollection.filter(i => i !== item), (item) => {return item['id']}))
  }

  const removeSelection = item => {
    setSelected(selected.filter(i => i !== item))
    setCurrentCollection( _.sortBy([...currentCollection, item], (item) => {return item['id']}))
  }

  const moveSelection = (fromIdx, toIdx) => {
    const selCopy = [...selected]
    selCopy.splice(toIdx, 0, selCopy.splice(fromIdx, 1)[0])
    setSelected(selCopy)
  }

  return (
      <div className={cnsvs_tests_errors ? "row has-error" : "row"}>
        <input name="activity[cnsvs_tests]" type="hidden" value={selected.map(s => s['code'])} />
        <div dangerouslySetInnerHTML={{__html: cnsvs_tests_errors}} />
        <div id="selections" className="col-md-6">
          {(selected.length > 0) && (
              <table className="table table-condensed table-striped">
                <tbody>
                  {selected.map((row, idx) => {
                    return (
                      <tr key={String(idx) + "-row"}>
                        {Object.keys(row).filter(k => k !== 'id').map((colRowKey, idx) => {
                          return (
                            <td key={idx}>{row[colRowKey]}</td>
                          )
                        })}
                        {!locked && (<td key="remove">
                          <a href='#'
                            onClick={ (e) => { e.preventDefault(); e.stopPropagation(); removeSelection(row)} }
                            title="Remove from selections"
                            className="btn btn-primary btn-xs small_right_space"
                            id={ 'remove_item_' + row.id + '_from_selections' }
                          >
                            <span className="fa fa-minus" aria-hidden="true"/>
                          </a>
                        </td>)}
                        {
                          ((!locked && selected.length > 1 && idx !== selected.length - 1) && (
                            <td key="move-down">
                              <a href='#'
                                onClick={ (e) => { e.preventDefault(); e.stopPropagation(); moveSelection(idx, idx + 1)} }
                                title="Move selection down"
                                className="btn btn-primary btn-xs small_right_space"
                                id={ 'move_item_down' + row.id }
                              >
                                <span className="fa fa-arrow-circle-down" aria-hidden="true"/>
                              </a>
                            </td>
                          ))
                        }
                        {
                          ((!locked && selected.length > 1 && idx !== 0) && (
                            <td key="move-up">
                              <a href='#'
                                onClick={ (e) => { e.preventDefault(); e.stopPropagation(); moveSelection(idx, idx - 1)} }
                                title="Move selection up"
                                className="btn btn-primary btn-xs small_right_space"
                                id={ 'remove_item_up' + row.id }
                              >
                                <span className="fa fa-arrow-circle-up" aria-hidden="true"/>
                              </a>
                            </td>
                          ))
                        }
                        {
                          ((!locked && selected.length > 2 && [0, selected.length - 1].includes(idx)) && (
                            <td key={"spacer-" + idx}>
                            </td>
                          ))
                        }
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )
          }
          {
            (selected.length === 0) && (<div className={cnsvs_tests_errors && "help-block"} >No Selections</div>)
          }
        </div>
        <div id="preselected-collection" className="col-md-6" hidden={locked}>
          <table className="table table-condensed table-striped">
            <tbody>
              {currentCollection.map((row,idx) => {
                return (
                  <tr key={String(idx) + "-row"}>
                    {Object.keys(row).filter(k => k !== 'id').map((colRowKey, idx) => {
                      return (
                        <td key={idx}>{row[colRowKey]}</td>
                      )
                    })}
                    <td key="add">
                      <a href='#'
                        onClick={ (e) => { e.preventDefault(); e.stopPropagation(); addSelection(row)} }
                        title="Add to selections"
                        className="btn btn-primary btn-xs small_right_space"
                        id={ 'add_item_' + row.id + '_to_selections' }
                      >
                        <span className="fa fa-plus" aria-hidden="true"/>
                      </a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
}

export default SelectList
