import React from "react"
import PropTypes from "prop-types"

const LinkReorder = props => {
  let title = "Move " + props.direction
  let href = props.path + "?direction=" + props.direction
  let css_class = "fa fa-arrow-" + props.direction

  if (props.onClick) {
    var extra_props = { onClick: props.onClick }
  } else {
    var extra_props = { "data-method": "put" }
  }

  return (
    <a
      href={href}
      title={title}
      {...extra_props}
      className="action-button"
      rel="nofollow"
    >
      <i className={css_class} />
    </a>
  )
}

LinkReorder.propTypes = {
  direction: React.PropTypes.oneOf(["up", "down"]).isRequired,
  path: React.PropTypes.string.isRequired,
  onClick: React.PropTypes.func
}

export default LinkReorder
