import React from "react"
import ActivityMetadataRow from "./ActivityMetadataRow"
import _ from "lodash"

const ActivityMetadata = props => {
  const { activity } = props

  const changeKey = (index, val) => {
    const { metadata } = activity
    metadata[index].key = val
    props.mutateActivity({ ...activity, metadata: metadata })
  }

  const changeValue = (index, val) => {
    const { metadata } = activity
    metadata[index].value = val
    props.mutateActivity({ ...activity, metadata: metadata })
  }

  const addRow = (name, e) => {
    e.preventDefault()
    const metadata = activity.metadata || []
    metadata.push({ key: name || "", value: "" })
    props.mutateActivity({ ...activity, metadata: metadata })
  }

  const removeRow = (index, e) => {
    e.preventDefault()
    const metadata = activity.metadata || []
    metadata.splice(index, 1)
    props.mutateActivity({ ...activity, metadata: metadata })
  }

  const renderKeyslist = () => {
    let keys = props.metadataKeys

    if (keys.length == 0) {
      return null
    }

    const links = keys.map(key => (
      <a
        href="#"
        title={`Add new metadata with "${key}"" set as a key`}
        key={key}
        onClick={e => addRow(key, e)}
      >
        {` ${key} `}
      </a>
    ))

    return (
      <div className="key-list">or choose from following keys: {links}</div>
    )
  }

  const renderData = () => {
    let metadata = props.activity?.metadata
    if (_.isEmpty(metadata)) {
      return null
    }

    return _.map(metadata, (data, index) => {
      return (
        <li key={`metadata-${index}`}>
          <ActivityMetadataRow
            data={data}
            index={index}
            onKeyChange={val => changeKey(index, val)}
            onValueChange={val => changeValue(index, val)}
            onDelete={e => removeRow(index, e)}
          />
        </li>
      )
    })
  }

  return (
    <div id="data-metadata-form-fields">
      <h3>Technical Integration Details</h3>

      <div dangerouslySetInnerHTML={{ __html: props.metadataErrors }} />

      <div className="form-group optional content_holder">
        <ul className={props.metadataErrors ? "has-error" : ""}>
          {renderData()}
        </ul>

        <div>
          <a href="#" onClick={e => addRow("", e)}>
            Add new from scratch
          </a>
          {renderKeyslist()}
        </div>
        <hr />
      </div>
    </div>
  )
}

export default ActivityMetadata
