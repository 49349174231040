import React from "react"
import AvailableProgramRow from "./AvailableProgramRow"

const AvailablePrograms = props => {
  const [sortField, setSortField] = React.useState(
    props.sortValue.split(" ")[0]
  )
  const [sortOrder, setSortOrder] = React.useState(
    props.sortValue.split(" ")[1]
  )

  const sortLink = (anchor, fieldName) => {
    var arrow = ""
    if (fieldName === sortField) {
      if (sortOrder === "asc") {
        arrow = " ▲"
      } else if (sortOrder === "desc") {
        arrow = " ▼"
      }
    }
    return (
      <a href="#" onClick={() => onSortLinkClick(fieldName)}>
        {anchor + arrow}
      </a>
    )
  }

  const onSortLinkClick = fieldName => e => {
    var order
    e.preventDefault()
    if (fieldName === sortField) {
      order = sortOrder === "asc" ? "desc" : "asc"
    } else {
      order = "asc"
    }
    setSortField(fieldName)
    setSortOrder(order)
    props.onSortLinkClick(fieldName + " " + order)
  }

  const renderRows = () =>
    props.data.map((program, index) => (
      <AvailableProgramRow
        data={program}
        hideSchools={props.hideSchools}
        index={index}
        key={index}
        selectProgram={props.selectProgram}
      />
    ))

  return (
    <div className="col-md-12">
      <table
        className="table table-condensed table-striped"
        id="available_programs_list"
      >
        <colgroup>
          <col span="1" style={{ width: "25px" }} />
          <col span="1" />
          <col span="1" style={{ width: `${props.hideSchools ? '75%' : '35%' }` }} />
          {!props.hideSchools && (<col span="1" style={{ width: "40%" }} />)}
        </colgroup>

        <thead>
          <tr>
            <th />
            <th>{sortLink("Name", "name")}</th>
            <th>{sortLink("Description", "description")}</th>
            {!props.hideSchools && (<th>School</th>)}
          </tr>
        </thead>

        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  )
}

export default AvailablePrograms
