export const setElementDisabled = function (elementSelector, value) {
  const elements = document.querySelectorAll(elementSelector)

  if (value) {
    elements.forEach((element) => {
      element.setAttribute('disabled', 'true')
    })
  } else {
    elements.forEach((element) => {
      element.removeAttribute('disabled')
    })
  }
}
