import React from "react";
import SelectedActivitiesList from "./SelectedActivitiesList";
import AvailableActivitiesList from "./AvailableActivitiesList";
import _ from "lodash";

const ProgramActivitiesSelector = (props) => {
  const [selectedActivities, _setSelected] = React.useState(
    (props.selected_activities || []).map((el) => ({
      ..._.omit(el, "skippable_by_default"),
      skippable: el.skippable_by_default,
      program_activity_type: "core",
    }))
  );

  const [selectedSwapActivities, _setSelectedSwap] = React.useState(
    (props.selected_swap_activities || []).map((el) => ({
      ..._.omit(el, "skippable_by_default"),
      skippable: el.skippable_by_default,
      program_activity_type: "swap",
    }))
  );
  const [hasPendingChanges, setHasPendingChanges] = React.useState(false);
  const [swapHasPendingChanges, setSwapHasPendingChanges] =
    React.useState(false);
  const [activitiesToRemove, setActivitiesToRemove] = React.useState([]);
  const [gameSwapsEnabled, setGameSwapsEnabled] = React.useState(
    selectedSwapActivities.length > 0
  );

  const setSelected = (arg) => {
    setHasPendingChanges(true);
    _setSelected(arg);
  };
  const setSelectedSwap = (arg) => {
    setSwapHasPendingChanges(true);
    _setSelectedSwap(arg);
  };

  const addToSelected = (el) => {
    setSelected([
      ...selectedActivities,
      {
        ..._.omit(el, "skippable_by_default"),
        skippable: el.skippable_by_default,
        program_activity_type: "core",
      },
    ]);
  };

  const addToSwap = (el) => {
    setSelectedSwap([
      ...selectedSwapActivities,
      {
        ..._.omit(el, "skippable_by_default"),
        skippable: el.skippable_by_default,
        program_activity_type: "swap",
      },
    ]);
  };

  const removeFromSelected = (index) => {
    if (
      selectedActivities[index] &&
      selectedActivities[index].activities_selected_id
    ) {
      setActivitiesToRemove([
        ...activitiesToRemove,
        { ...selectedActivities[index] },
      ]);
    }

    setSelected([
      ...selectedActivities.slice(0, index),
      ...selectedActivities.slice(index + 1),
    ]);
  };
  const removeFromSelectedSwap = (index) => {
    if (
      selectedSwapActivities[index] &&
      selectedSwapActivities[index].activities_selected_id
    ) {
      setActivitiesToRemove([
        ...activitiesToRemove,
        { ...selectedSwapActivities[index] },
      ]);
    }
    setSelectedSwap([
      ...selectedSwapActivities.slice(0, index),
      ...selectedSwapActivities.slice(index + 1),
    ]);
  };

  const onCheckToggle = () => {
    setGameSwapsEnabled(!gameSwapsEnabled);
  };

  return (
    <div>
      <div>
        <input
          value={true}
          style={{ margin: 5 }}
          type="checkbox"
          checked={gameSwapsEnabled}
          onChange={() => onCheckToggle()}
          name="swappable"
        />
        <label htmlFor="swappable" style={{ margin: 5 }}>
          Enable Game Swaps
        </label>
      </div>
      <h4
        className="sub-title"
        style={{ display: "inline-block", marginTop: 10 }}
      >
        Core Activities
      </h4>
      <SelectedActivitiesList
        selectedActivities={selectedActivities}
        setSelected={setSelected}
        removeFromSelected={removeFromSelected}
        swappable={gameSwapsEnabled}
      />
      {gameSwapsEnabled && (
        <div>
          <h4 className="sub-title" style={{ display: "inline-block" }}>
            Swap Activities
          </h4>
          <SelectedActivitiesList
            selectedActivities={selectedSwapActivities}
            setSelected={setSelectedSwap}
            removeFromSelected={removeFromSelectedSwap}
            swappable={gameSwapsEnabled}
          />
        </div>
      )}
      <div className="row col-md-12">
        <button className="btn btn-default bottom_space" data-disable="true">
          {props.submit_button_label} Program
        </button>
      </div>
      <AvailableActivitiesList
        availableActivities={props.available_activities}
        addToSelected={addToSelected}
        addToSwap={addToSwap}
        gameSwapsEnabled={gameSwapsEnabled}
      />
      {/* this is a hidden section that adds form elements to destroy */}
      {activitiesToRemove.map((item) => (
        <span key={item.activities_selected_id}>
          <input
            type="hidden"
            value={item.id}
            name="program[program_activities_attributes][][activity_id]"
          />
          <input
            type="hidden"
            value="1"
            name="program[program_activities_attributes][][_destroy]"
          />
          <input
            type="hidden"
            value={item.activities_selected_id}
            name="program[program_activities_attributes][][id]"
          />
        </span>
      ))}
    </div>
  );
};

export default ProgramActivitiesSelector;
