import React from "react"
import ActivityAttribute from "./ActivityAttribute"
import ActivityDirectionsData from "./ActivityDirectionsData"
import { get } from "lodash"
import DraggableLi from "../DraggableLi"

const ActivityData = props => {
  const handleOnSiteClick = () => {
    props.mutateActivity({
      ...props.activity,
      on_site: !props.activity.on_site
    })
  }

  const setAttributeField = (index, key, value) => {
    const result = Array.from(get(props.activity, "data.attributes", []))
    result[index] = { ...result[index], [key]: value }
    props.mutateActivity({
      ...props.activity,
      data: {
        ...props.activity.data,
        attributes: result
      }
    })
  }

  const positionAttribute = (startIndex, endIndex) => {
    const result = Array.from(get(props.activity, "data.attributes", []))
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    props.mutateActivity({
      ...props.activity,
      data: {
        ...props.activity.data,
        attributes: result
      }
    })
  }

  const deleteAttribute = index => {
    const result = Array.from(get(props.activity, "data.attributes", []))
    result.splice(index, 1)
    props.mutateActivity({
      ...props.activity,
      data: {
        ...props.activity.data,
        attributes: result
      }
    })
  }

  const addNewData = e => {
    e.preventDefault()
    props.mutateActivity({
      ...props.activity,
      data: {
        ...props.activity?.data || {},
        attributes: [...get(props.activity, "data.attributes", []), { id: "" }]
      }
    })
  }

  const { activity } = props
  const attributes = activity?.data?.attributes || []

  const data = attributes.map((attr, index) => ({
    id: `${attr}${index}`,
    contents: (
      <ActivityAttribute
        attribute={attr}
        index={index}
        id={attr.id}
        onChange={setAttributeField}
        onSiteActivity={activity.on_site}
        onDelete={deleteAttribute}
        label={attr.label}
        type={attr.type}
        answer={attr.answer}
        disabled={activity.locked}
        disabledLinkClass={props.disabledLinkClass}
      />
    )
  }))

  return (
    <div id="data-attributes-form-fields">
      <hr />

      <ActivityDirectionsData {...props} />

      <h3>Activity Response Data</h3>

      <div dangerouslySetInnerHTML={{ __html: props.dataErrors }} />

      <div className="form-group optional">
        <ul className={props.dataErrors ? "has-error" : ""}>
          {data && data.length > 0 && (
            <DraggableLi items={data} onReorder={positionAttribute} />
          )}
        </ul>
        <div className="form-group boolean optional activity_on_site">
          <div className="checkbox">
            <input
              value="0"
              type="hidden"
              name="activity[on_site]"
              disabled={activity?.locked}
            />
            <label className="boolean optional" htmlFor="activity_on_site">
              <input
                className="boolean optional"
                type="checkbox"
                value={activity?.on_site ? "1" : "0"}
                checked={activity?.on_site || false}
                name="activity[on_site]"
                onChange={handleOnSiteClick}
                id="activity_on_site"
                disabled={activity?.locked}
              />
              Display data fields to students
            </label>
          </div>
        </div>
        <a
          href="#"
          className={"btn btn-default " + props.disabledLinkClass}
          onClick={addNewData}
        >
          Add Response
        </a>

        <hr />
      </div>
    </div>
  )
}

export default ActivityData
