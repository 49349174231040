import React from "react";

const ActivityGameSelect = (props) => {
  return (
    <div className="form-group select optional form-group-valid">
      <label className="form-control-label select optional" htmlFor="game_id">
        Game
      </label>
      <select
        name="activity[game_id]"
        id="game_id"
        className="form-control select optional"
        value={props.activity.game_id ? props.activity.game_id : "default"}
        onChange={(e) => props.onSelectChange(e.target.value)}
        disabled={props.activity.locked}
      >
        <option value="default" disabled hidden>
          Choose a game
        </option>
        {props.games
          .filter((g) => g.provider_id == props.activity.provider_id)
          .map((g) => (
            <option key={g.id} value={g.id}>
              {g.display_name}
            </option>
          ))}
      </select>
    </div>
  );
};

export default ActivityGameSelect;
