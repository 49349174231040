import React from "react";
import AddActivitiesList from "./AddActivitiesList";
import CurrentSessionActivitiesList from "./CurrentSessionActivitiesList";
import Modal from "../Modal";
import _ from "lodash";
import { isEqual } from "lodash";

const ModifyCurrentSession = (props) => {
  const CONTAINER_ID = "flash_container";

  const SWAP_MODAL_BODY = (
    <p>
      To finish this game and exchange it for the next available Game Swap in
      the student's program click the SWAP button.
      <br />
      <br />
      This change will be applied to all future TCI sessions.
    </p>
  );

  const SECONDARY_SWAP_MODAL_BODY = (
    <p>
      You are about to update a students's program by replacing this game with
      the text available Game Swap.{" "}
      <span style={{ fontWeight: "bold" }}>
        This change will be applied to all future TCI sessions.
      </span>
      <br />
      <br />
      If you want to skip this game for today's session only click Cancel and
      use Skip instead.
    </p>
  );

  const UPDATE_MODAL_BODY =
    " You are about to update the current session.\
                            Are you sure you want to do this?";
  const [showModal, setShowModal] = React.useState(false);
  const [modalBody, setModalBody] = React.useState(null);
  const [modalAlert, setModalAlert] = React.useState(null);
  const [modalActions, setModalActions] = React.useState(null);
  const [submitButton, setSubmitButton] = React.useState(React.createRef());
  const [propCurrentSessionActivities, _setPropActivities] = React.useState(
    props.current_session_activities || []
  );

  const [currentSessionActivities, _setSelected] = React.useState(
    props.current_session_activities || []
  );

  const STUDENT_PROGRAM_ACTIVITY_ID =
    props.student_program_activity.student_program_id;

  const [availableActivities, setAvailableActivities] = React.useState(
    (props.AvailableActivities || []).map((el) => ({
      activity_id: el.id,
      name: el.name,
      activity_response_id: null,
      id: null,
      position: null,
      description: el.description,
      activity_type: el.activity_type,
      program_activity_type: "core",
      skippable: true,
      state: "pending",
      student_program_activity_state_id: null,
      student_program_id: STUDENT_PROGRAM_ACTIVITY_ID,
      swappable: false,
    }))
  );
  const activitiesChanged = !isEqual(
    currentSessionActivities.map((activity) => {
      return { id: activity.id, state: activity.state };
    }),
    propCurrentSessionActivities.map((activity) => {
      return {
        id: activity.id,
        state: activity.state,
      };
    })
  );

  const updatePropCurrentSessionWithSwap = (
    swapInActivity,
    swapOutActivityId
  ) => {
    const copyPropCurrentSessionActivities = [...propCurrentSessionActivities];

    const removedIndex = propCurrentSessionActivities.findIndex(
      (a) => a.id == swapOutActivityId
    );
    copyPropCurrentSessionActivities.splice(removedIndex, 1, swapInActivity);
    _setPropActivities(copyPropCurrentSessionActivities);
  };

  const updateCurrentSessionWithSwap = (swapInActivity, swapOutActivityId) => {
    const copyCurrentSessionActivities = [...currentSessionActivities];

    const removedIndex = currentSessionActivities.findIndex(
      (a) => a.id == swapOutActivityId
    );

    copyCurrentSessionActivities.splice(removedIndex, 1, swapInActivity);
    _setSelected(copyCurrentSessionActivities);
  };

  const updateCurrentSession = (el) => {
    _setSelected([...el]);
  };

  const addToCurrentSession = (el) => {
    _setSelected([...currentSessionActivities, el]);
  };

  const sendRequest = (options) => {
    $.ajax({
      url: options.path,
      method: options.method,
      dataType: "JSON",
      data: options.params,
      cache: false,
      success: options.success,
      error: options.error,
    });
  };
  const skipFromCurrentSession = (e, id) => {
    e.preventDefault();
    setModalBody(SWAP_MODAL_BODY);
    setModalActions([
      { title: "Cancel", callback: () => setShowModal(false) },
      {
        title: "Confirm",
        callback: () => {
          sendRequest({
            path: props.collectionPath + "/skip_activity/" + id,
            method: "PATCH",
            params: { spa_id: id },
            success: () => window.location.reload(),
            error: (e) => alert(e),
          });
        },
      },
    ]);
    setShowModal(true);
  };
  const onSubmitClick = (e) => {
    e.preventDefault();
    setModalBody(UPDATE_MODAL_BODY);
    setModalActions([
      { title: "Cancel", callback: () => setShowModal(false) },
      {
        title: "Confirm",
        callback: () => {
          sendRequest({
            path: props.collectionPath,
            method: "PUT",
            params: {
              current_session_activities: currentSessionActivities,
              student_program_id: STUDENT_PROGRAM_ACTIVITY_ID,
            },
            success: (response) => {
              window.location.href = response.path;
            },
            error: (e) => {
              alert(e);
            },
          });
        },
      },
    ]);
    setShowModal(true);
  };

  const onSwapClick = (e, item_id, program_activity_state_id) => {
    e.preventDefault();
    setModalBody(SWAP_MODAL_BODY);
    setModalActions([
      { title: "Cancel", callback: () => setShowModal(false) },
      {
        title: "Swap",
        callback: () => {
          onSecondarySwapClick(e, item_id, program_activity_state_id);
        },
      },
    ]);
    setShowModal(true);
  };

  const onSecondarySwapClick = (e, item_id, program_activity_state_id) => {
    e.preventDefault();
    setModalBody(SECONDARY_SWAP_MODAL_BODY);
    setModalActions([
      {
        title: "Cancel",
        callback: () => {
          setShowModal(false);
          setModalAlert(null);
        },
      },
      {
        title: "Confirm",
        callback: () => {
          sendRequest({
            path: props.collectionPath + "/swap_state",
            method: "PUT",
            params: {
              spas_id: program_activity_state_id,
              student_program_id: STUDENT_PROGRAM_ACTIVITY_ID,
            },
            success: (response) => {
              window.showFlash(
                response.variant,
                response.message,
                CONTAINER_ID
              );
              if (response.student_program_activity) {
                updatePropCurrentSessionWithSwap(
                  response.student_program_activity,
                  item_id
                );
                updateCurrentSessionWithSwap(
                  response.student_program_activity,
                  item_id
                );
              }
              setShowModal(false);
            },
            error: (r, e) => {
              setModalAlert(r.responseJSON.message);
            },
          });
        },
      },
    ]);
    setShowModal(true);
  };

  const removeFromCurrentSession = (index) => {
    var currentActivitiesList = currentSessionActivities;
    currentActivitiesList.splice(index, 1);
    updateCurrentSession(currentActivitiesList);
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-8">
          {activitiesChanged && (
            <div style={{ fontWeight: "bold", color: "red" }}>
              To apply your changes, press the SUBMIT button.
            </div>
          )}
        </div>

        <div className="col-md-4">
          <button
            type="button"
            className="btn btn-primary pull-right small_bottom_space"
            onClick={(e) => onSubmitClick(e)}
            disabled={!activitiesChanged}
          >
            Submit
          </button>
        </div>
      </div>
      <>
        <CurrentSessionActivitiesList
          currentSessionActivities={currentSessionActivities}
          updateCurrentSession={updateCurrentSession}
          removeFromCurrentSession={removeFromCurrentSession}
          skipFromCurrentSession={skipFromCurrentSession}
          onSwapClick={onSwapClick}
        />
        <div>
          <AddActivitiesList
            availableActivities={availableActivities}
            addToCurrentSession={addToCurrentSession}
          />
        </div>
      </>
      <Modal
        show={showModal}
        actions={modalActions}
        body={modalBody}
        onCancel={() => setShowModal(false)}
        title="Are you sure?"
        alertMessage={modalAlert}
      />
    </React.Fragment>
  );
};

export default ModifyCurrentSession;
