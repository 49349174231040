import React from "react"
import DraggableTable from "../DraggableTable"

const SelectedPrograms = props => {
  const serializeItems = (props.data || []).map((item, ix) => ({
    id: `queue-template-selected-program-${ix}`,
    contents: (
      <React.Fragment>
        <td>
          <i
            onClick={() => props.removeFromSelected(ix)}
            className="btn btn-primary btn-circle btn-sm"
            style={{  lineHeight: 0,
              padding: "5px 6px",
              margin: 2,
              height: 26,
              width: 26 }}
            title="Remove from Collection"
          >
            <span
              className="fa fa-trash-o"
              aria-hidden="true"
              style={{ height: 10, width: 10, paddingRight: 1, paddingTop: 2 }}
            />
          </i>
        </td>
        <td>
          <input
            min="1"
            defaultValue={item.repetitions || 1}
            style={{ width: "70px" }}
            className="numeric integer optional form-control"
            type="number"
            step="1"
            name="queue_template[queue_template_programs_attributes][][repetitions]"
          />
        </td>
        <td>{item.name}</td>
        <td>{item.description}</td>
        <td>
          {(item.schools || []).map(name => (
            <li key={name}>{name}</li>
          ))}

          {/* start form data */}

          <input
            type="hidden"
            value={item.id}
            name="queue_template[queue_template_programs_attributes][][program_id]"
          />
          {item.program_queue_template_id && (
            <input
              multiple
              type="hidden"
              value={item.program_queue_template_id}
              name="queue_template[queue_template_programs_attributes][][id]"
            />
          )}
          <input
            type="hidden"
            step="1"
            value={ix}
            name="queue_template[queue_template_programs_attributes][][position]"
          />
          {/* end form data */}
        </td>
        <td/>
      </React.Fragment>
    )
  }))

  return (
    <table
      className="table table-condensed table-striped"
      id="selected_programs_list"
    >
      <colgroup>
        <col span="1" style={{ width: "80px" }} />
        <col span="1" style={{ width: "80px" }} />
        <col span="1" />
        <col span="1" style={{ width: "30%" }} />
        <col span="1" style={{ width: "35%" }} />
        <col span="1" style={{ width: "25px" }} />
      </colgroup>

      <thead>
        <tr>
          <th />
          <th>Repetitions</th>
          <th>Name</th>
          <th>Description</th>
          <th>School</th>
          <th />
        </tr>
      </thead>

      <DraggableTable items={serializeItems} onReorder={props.onReorder} />
    </table>
  )
}

export default SelectedPrograms
