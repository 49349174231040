import React from "react";
import { Button, Modal } from "react-bootstrap";

const CarsModal = (props) => {
  const { order, show, onCancel, title, body, backdrop, cancel, alertMessage } =
    props;
  if (!show) return null;
  const actions = props.actions.map((action) => (
    <Button
      variant="primary"
      bsStyle={action.title.toLowerCase() === "confirm" ? "danger" : "default"}
      key={"modalActionButton" + action.title}
      onClick={action.callback}
    >
      {action.title}
    </Button>
  ));
  const theBackdrop = backdrop === null ? true : backdrop;
  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        {order ? [actions, cancel] : [cancel, actions]}
      </Modal.Footer>
      <div style={{ fontWeight: "bold", color: "red", paddingLeft: "10px" }}>
        {alertMessage}
      </div>
    </Modal>
  );
};

export default CarsModal;
