import React from "react"
import RailsForm from "../form/RailsForm"
import { Hidden } from "../form/inputs"

const CollectionItem = props => {
  const title =
    "Add " + props.displayedItemName + " to " + props.displayedCollectionName
  const hidden_field_name =
    props.collection_name + "[" + props.item_name + "_id]"
  const hidden_field_id = props.collection_name + "_" + props.item_name + "_id"

  return (
    <li id={props.displayedItemName + props.item.id} key={props.item.id}>
      <RailsForm action={props.save_path} method="post">
        <Hidden
          value={props.item.id}
          name={hidden_field_name}
          inputId={hidden_field_id}
        />
        <button
          title={title}
          type="submit"
          data-item-id={props.item.id}
          style={{borderRadius: 40}}
          className="btn btn-primary btn-xs"
          id={"add_item_" + props.item.id + "_to_collection_link"}
        >
          <span className="fa fa-chevron-left" aria-hidden="true" />
        </button>{" "}
        {props.label}
      </RailsForm>
    </li>
  )
}

export default CollectionItem
