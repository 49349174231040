import React from 'react'
import {SelectOption, Group} from './inputs'

const Select = props => {
  const getSelfOrValue = (object, value) => {
    if (typeof object === "string") {
      return object
    } else {
      return object[value]
    }
  }

  const { label, inputId, name, options, ...other } = props
  const renderOptions = options.map((option, index) => (
    <SelectOption
      key={inputId + "option" + index}
      value={getSelfOrValue(option, "value")}
      label={getSelfOrValue(option, "label")}
    />
  ))
  return (
    <Group label={label} inputId={inputId}>
      <select
        {...other}
        defaultValue={props.selectedIndex}
        className="form-control select2"
        id={inputId}
        name={name}
      >
        <option />
        {renderOptions}
      </select>
    </Group>
  )
}

export default Select
