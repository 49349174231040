import React from "react"

const AvailablePrograms = props => {
  const sortField = props.sortValue.split(" ")[0]
  const sortOrder = props.sortValue.split(" ")[1]

  const onSortLinkClick = fieldName => {
    return function(e) {
      let order
      e.preventDefault()
      if (fieldName === sortField) {
        order = sortOrder === "asc" ? "desc" : "asc"
      } else {
        order = "asc"
      }
      props.onSortLinkClick(fieldName + " " + order)
    }
  }

  const sortLink = (anchor, fieldName) => {
    var arrow = ""
    if (fieldName === sortField) {
      if (sortOrder === "asc") {
        arrow = " ▲"
      } else if (sortOrder === "desc") {
        arrow = " ▼"
      }
    }
    return (
      <a href="#" onClick={onSortLinkClick(fieldName)}>
        {anchor + arrow}
      </a>
    )
  }

  const serializeItems = (props.data || []).map((item, ix) => (
    <tr key={item.name}>
      <td>
        <i
          onClick={() => props.addToSelected(item)}
          className="btn btn-primary btn-sm"
          style={{  lineHeight: 0,
                    padding: '5px 6px',
                    margin: 2,
                    height: 24,
                    width: 24 }}
          title="Add to collection"
        >
          <span
            className="fa fa-plus"
            aria-hidden="true"
            style={{ height: 10, width: 10, paddingRight: 4, paddingTop: 2 }}
            />
        </i>
      </td>
      <td>{item.name}</td>
      <td>{item.description}</td>
      <td>
        {(item.schools || []).map(name => (
          <li key={name}>{name}</li>
        ))}
      </td>
    </tr>
  ))

  return (
    <table
      className="table table-condensed table-striped"
      id="available_programs_list"
    >
      <colgroup>
        <col span="1" style={{ width: "25px" }} />
        <col span="1" />
        <col span="1" style={{ width: "35%" }} />
        <col span="1" style={{ width: "40%" }} />
      </colgroup>

      <thead>
        <tr>
          <th />
          <th>{sortLink("Name", "name")}</th>
          <th>{sortLink("Description", "description")}</th>
          <th>School</th>
        </tr>
      </thead>

      <tbody>{serializeItems}</tbody>
    </table>
  )
}

export default AvailablePrograms
