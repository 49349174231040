import React from "react"
import ActivityData from "./ActivityData"
import ActivityMetadata from "./ActivityMetadata"

const BLANK_ERROR = '<div class="error_text"></div>'

const hasError = input => (input === BLANK_ERROR ? null : input)

const Activity = ({
  activity,
  activityLinkError,
  dataErrors,
  directionsError,
  locked,
  metadataErrors,
  metadataKeys
}) => {
  const [_activity, mutateActivity] = React.useState(activity)

  const errors = {
    metadataErrors: hasError(metadataErrors),
    activityLinkError: hasError(activityLinkError),
    dataErrors: hasError(dataErrors),
    directionsError: hasError(directionsError)
  }

  return (
    <div>
      <ActivityData
        {...errors}
        locked={locked}
        metadataKeys={metadataKeys}
        activity={_activity}
        mutateActivity={mutateActivity}
        disabledLinkClass={activity?.locked ? "disabled" : ""}
      />
      <ActivityMetadata
        {...errors}
        locked={locked}
        metadataKeys={metadataKeys}
        activity={_activity}
        mutateActivity={mutateActivity}
        disabledLinkClass={activity?.locked ? "disabled" : ""}
      />
    </div>
  )
}

export default Activity
