import React from "react";
import _ from "lodash";

const AddActivitiesList = (props) => {
  const [search, setSearch] = React.useState("");

  let items = props.availableActivities || [];
  if (_.trim(search).length > 0) {
    const cleanSearch = _.trim(search).toLowerCase();
    items = items.filter(
      (el) => el.name.toLowerCase().indexOf(cleanSearch) > -1
    );
  }

  const serializeItems = items.map((item, ix) => (
    <tr key={item.name}>
      <td>
        <i
          onClick={() => props.addToCurrentSession(item)}
          style={{  lineHeight: 0,
                    padding: "5px 6px",
                    margin: 2,
                    height: 24,
                    width: 24 }}
          className="btn btn-primary btn-sm"
          title="Edit current session"
        >
          <span
            className="fa fa-plus"
            aria-hidden="true"
            style={{ height: 10, width: 10, paddingRight: 1, paddingTop: 1 }}
            />
        </i>
      </td>

      <td>{item.name}</td>
      <td>{item.description}</td>
      <td>{item.activity_type}</td>
    </tr>
  ));

  return (
    <React.Fragment>
      <div className="row">
        <span className="col-md-8">
          <h4 className="sub-title" style={{ display: "inline-block" }}>
            Add Activities
          </h4>
        </span>
        <span
          className="col-md-4"
        >
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </span>
      </div>
      <div className="row">
        <div className="col-md-12">
          <table
            className="table table-condensed table-striped w-100 d-block d-md-table"
            id="selected_activities_list"
          >
            <thead>
              <tr>
                <th />
                <th>Name</th>
                <th>Description</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>{serializeItems}</tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddActivitiesList;
