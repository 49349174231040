import React from "react"
import ActivityOnSiteAttributes from "./ActivityOnSiteAttributes"

const ActivityAttribute = props => {
  const htmlIdFor = fieldName => `attribute_${fieldName}_${props.index}`

  const activityAdditionalAttributes = props.onSiteActivity ? (
    <ActivityOnSiteAttributes
      label={props.label}
      type={props.type}
      answer={props.answer}
      htmlIdFor={htmlIdFor}
      index={props.index}
      onChange={props.onChange}
      attribute={props.attribute}
      disabled={props.disabled}
      disabledLinkClass={props.disabledLinkClass}
    />
  ) : null

  const { attribute } = props

  return (
    <div className="row">
      <div className="col-xs-1 ">
        <i className="fa fa-bars" />
      </div>
      <div className="col-xs-4">
        <div className="form-group string required">
          <label
            className="string required control-label"
            htmlFor={htmlIdFor("id")}
          >
            <abbr title="required">*</abbr> Id
          </label>
          <input
            autoFocus
            name="activity[data][attributes][][id]"
            id={htmlIdFor("id")}
            className="string required form-control"
            value={props.attribute.id}
            disabled={props.disabled}
            onChange={e => props.onChange(props.index, "id", e.target.value)}
          />
          <input
            name="activity[data][attributes][][position]"
            type="hidden"
            value={props.index + 1}
          />
        </div>
      </div>

      {activityAdditionalAttributes}

      <div className="col-xs-1 ">
        <div className="form-group string">
          <div className="activity-attributes-controls">
            <a
              className={props.disabledLinkClass}
              href="#"
              id={htmlIdFor("delete")}
              onClick={() => props.onDelete(props.index)}
            >
              <i className="fa fa-trash" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActivityAttribute
