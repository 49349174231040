import React from "react";

const ActivityGameCheckbox = (props) => {
  return (
    <fieldset className="form-group boolean optional">
      <div className="form-check">
        <input type="hidden" name={`activity[${props.property}]`} value="false"/>
        <input
          onChange={() => props.onCheckboxChange(props.property)}
          className="form-check-input boolean optional"
          type="checkbox"
          id={props.property}
          name={`activity[${props.property}]`}
          value="true"
          checked={props.activity[props.property]}
          disabled={!props.activity.game_id}
        />
        <label
          className="form-check-label boolean optional"
          htmlFor={props.property}
        >
          {props.gameAttribute}
        </label>
      </div>
    </fieldset>
  );
};

export default ActivityGameCheckbox;
