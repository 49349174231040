import React from "react"
import SchoolCustomAttributesRow from "./SchoolCustomAttributesRow"

const BLANK_ERROR = '<div class="error_text"></div>'

const SchoolCustomAttributes = props => {
  const [attributes, setAttributes] = React.useState(props.attributes)

  const addAttribute = e => {
    e.preventDefault()
    setAttributes([...attributes, { id: "", label: "" }])
  }

  const setAttribute = (index, key, val) => {
    const mutated = [...attributes]
    mutated[index][key] = val
    setAttributes(mutated)
  }

  const removeAttribute = (index, e) => {
    e.preventDefault()
    const mutated = [...attributes]
    mutated.splice(index, 1)
    setAttributes(mutated)
  }

  const rows = () =>
    (attributes || []).map((data, index) => (
      <li key={`attribute-${index}`}>
        <SchoolCustomAttributesRow
          data={data}
          index={index}
          id={data.id}
          setAttribute={setAttribute}
          onDelete={removeAttribute}
        />
      </li>
    ))

  const cleanedErrors = props.errors === BLANK_ERROR ? null : props.errors

  return (
    <div id="custom-attributes-form-fields">
      <h3>Custom attributes</h3>

      <div dangerouslySetInnerHTML={{ __html: cleanedErrors }} />

      <div className="form-group optional content_holder">
        <ul className={cleanedErrors ? "has-error" : ""}>{rows()}</ul>

        <div>
          <a href="#" onClick={addAttribute}>
            Add custom attribute
          </a>
        </div>

        <hr />
      </div>
    </div>
  )
}

export default SchoolCustomAttributes
