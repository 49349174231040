import React from "react";
import ActivityGameCheckbox from "./ActivityGameCheckbox";
import ActivityGameSelect from "./ActivityGameSelect";

const ActivityGame = ({activity, games, providers, clickTankEnabled}) => {

  const [_activity, mutateActivity] = React.useState(activity);

  const handleCheckboxChange = (attribute) => {
    const changedActivity = { ..._activity, [attribute]: !_activity[attribute] };
    mutateActivity(changedActivity);
  };

  const handleProviderChange = (providerId) => {
    const changedActivity = { ...activity, provider_id: providerId };
    mutateActivity(changedActivity);
  };

  const providerSelect = document.getElementById("activity_provider_id");
  if (providerSelect.getAttribute("listener") !== "true") {
    providerSelect.addEventListener("change", (e) =>
      handleProviderChange(e.target.value)
    );
    providerSelect.setAttribute("listener", "true");
  }

  const handleGameChange = (gameId) => {
    const activity = { ..._activity };
    activity.game_id = gameId;
    mutateActivity({
      ...activity,
    });
  };

  const isGameProviderSelected = () => {
    let visualIdProvider = providers.find((p) => p.name == "Visual Id");
    let memoryProvider = providers.find((p) => p.name == "Memory");

    return (
      visualIdProvider.id == _activity.provider_id ||
      memoryProvider.id == _activity.provider_id
    );
  };

  if (isGameProviderSelected()) {
    return (
      <div>
        <ActivityGameSelect
          games={games}
          activity={_activity}
          onSelectChange={handleGameChange}
        />
        <ActivityGameCheckbox
          onCheckboxChange={handleCheckboxChange}
          property={"developer_mode"}
          gameAttribute={"Developer Mode"}
          activity={_activity}
        />
        <ActivityGameCheckbox
          onCheckboxChange={handleCheckboxChange}
          property={"level_slider"}
          gameAttribute={"Level Slider"}
          activity={_activity}
        />
        <ActivityGameCheckbox
          onCheckboxChange={handleCheckboxChange}
          property={"colorblind_mode"}
          gameAttribute={"Colorblind Mode"}
          activity={_activity}
        />
        { //  TODO: Remove the check for the click tank when we are ready to deploy it to production.
          clickTankEnabled && <ActivityGameCheckbox
          onCheckboxChange={handleCheckboxChange}
          property={"click_tank"}
          gameAttribute={"Click Tank Mode"}
          activity={_activity}
        />}
      </div>
    );
  } else {
    return null;
  }
};

export default ActivityGame;
