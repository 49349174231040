import React from "react"
import { Button, Modal } from "react-bootstrap"

const ActivityModal = props => {
  const [buttonDisabled, setButtonDisabled] = React.useState(false)

  const startFormSubmit = () => {
    setButtonDisabled(true)
    document.forms["goToOnlineActivityForm"].submit()
  }

  return (
    <div>
      <Modal show={props.showModal} onHide={() => props.setModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <form name="goToOnlineActivityForm" action={props.url} method="POST">
          <Modal.Body>
            You are going to a new site. You will be returned after you finish
            your activity.
            {props.inputs.map((input, i) => (
              <input
                key={i}
                name={input.name}
                value={input.value}
                type="hidden"
              />
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn btn-launch"
              type="submit"
              onClick={startFormSubmit}
              disabled={buttonDisabled}
            >
              {buttonDisabled ? "Loading" : "Go"}
            </Button>
            <Button
              className="btn btn-info"
              onClick={() => props.setModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}

export default ActivityModal
