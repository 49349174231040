import React from "react"
import QuestionEmojiOption from "../activity_reflection/QuestionEmojiOption"
import ActivityOption from "./ActivityOption"
import { get } from "lodash"

const ActivityAttributeOptions = props => {
  const onOptionAdd = () => {
    const options = props.attribute.options || []
    options.push("")
    props.onChange(props.index, "options", options)
  }

  const onOptionChange = (optionIndex, val) => {
    const { options } = props.attribute
    options[optionIndex] = val
    props.onChange(props.index, "options", options)
  }

  const onOptionRemove = (optionIndex, val) => {
    const { options } = props.attribute
    options.splice(optionIndex, 1)
    props.onChange(props.index, "options", options)
  }

  const onEmojiAdd = () => {
    const emojiOptions = props.attribute.emojiOptions || []
    emojiOptions.push({ display_name: "", file_data: null })
    props.onChange(props.index, "emojiOptions", emojiOptions)
  }

  const onEmojiNameUpdate = (optionIndex, val) => {
    const emojiOptions = props.attribute.emojiOptions
    emojiOptions[optionIndex]["display_name"] = val
    props.onChange(props.index, "emojiOptions", emojiOptions)
  }

  const onEmojiRemove = index => {
    const emojiOptions = props.attribute.emojiOptions
    emojiOptions.splice(index, 1)
    props.onChange(props.index, "emojiOptions", emojiOptions)
  }

  const onEmojiImageUpdate = (optionIndex, val) => {
    const emojiOptions = props.attribute.emojiOptions
    emojiOptions[optionIndex]["file_data"] = val
    props.onChange(props.index, "emojiOptions", emojiOptions)
  }

  const options = () => {
    if (props.type === "emoji" && props.attribute['emojiOptions']) {
      return get(props.attribute, "emojiOptions", []).map(
        (option, optionIndex) => (
          <QuestionEmojiOption
            key={"attributeEmojiOption" + optionIndex}
            value={option}
            name={props.namePrefix + "[]"}
            onChange={e => onEmojiNameUpdate(optionIndex, e.target.value)}
            onImageChange={file_data =>
              onEmojiImageUpdate(optionIndex, file_data)
            }
            onRemove={() => onEmojiRemove(optionIndex)}
            disabled={props.disabled}
            disabledLinkClass={props.disabledLinkClass}
          />
        )
      )
    } else {
      return get(props.attribute, "options", []).map((option, optionIndex) => (
        <ActivityOption
          key={"attributeOption" + optionIndex}
          value={option}
          name={props.namePrefix + "[]"}
          onChange={e => onOptionChange(optionIndex, e.target.value)}
          onRemove={e => onOptionRemove(optionIndex)}
          disabled={props.disabled}
          disabledLinkClass={props.disabledLinkClass}
        />
      ))
    }
  }

  return (
    <div>
      <label className="string required control-label">Options</label>
      {options()}
      <a
        className={"btn btn-link " + props.disabledLinkClass}
        onClick={props.type === "emoji" ? onEmojiAdd : onOptionAdd}
      >
        add option
      </a>
    </div>
  )
}

export default ActivityAttributeOptions
