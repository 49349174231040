import React from "react"
import { Csrf } from "./inputs"

const RailsForm = props => {
  const csrfField = () => {
    if (!props.method || props.method.match(/^get$/i)) {
      return false
    } else {
      return <Csrf />
    }
  }

  const methodField = () => {
    if (!props.method || props.method.match(/^(get)|(post)$/i)) {
      return false
    } else {
      return <input type="hidden" name="_method" value={props.method} />
    }
  }

  const methodName = () => {
    if (!props.method) return ""
    if (props.method.match(/^(get)|(post)$/i)) {
      return props.method
    } else {
      return "post"
    }
  }

  return (
    <form method={methodName()} action={props.action}>
      {csrfField()}
      {methodField()}
      {props.children}
    </form>
  )
}

export default RailsForm
