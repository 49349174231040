import React from "react"
import QuestionEmojiOption from "./QuestionEmojiOption"
import QuestionOption from "./QuestionOption"

const QuestionOptions = props => {
  const renderOptions = () => {
    if (props.questionType == "emoji") {
      return (props.options || []).map((option, index) => (
        <QuestionEmojiOption
          key={"question" + props.questionIndex + "Options" + index}
          name={props.namePrefix + "[]"}
          onChange={e =>
            props.onChange(index, { ...option, display_name: e.target.value })
          }
          onImageChange={img =>
            props.onChange(index, { ...option, file_data: img })
          }
          onRemove={() => props.onRemoveOption(index)}
          value={option}
        />
      ))
    } else {
      return (props.options || []).map((option, index) => (
        <QuestionOption
          key={"question" + props.questionIndex + "Options" + index}
          name={props.namePrefix + "[]"}
          onChange={e => props.onChange(index, e.target.value)}
          onRemove={() => props.onRemoveOption(index)}
          value={option}
        />
      ))
    }
  }

  if (props.questionType == "text") return null

  return (
    <div>
      <label>Options</label>

      {renderOptions()}

      <a
        href="#"
        onClick={e => {
          e.preventDefault()
          props.onAddOption()
        }}
        className="btn btn-link"
      >
        + Add option
      </a>
    </div>
  )
}

export default QuestionOptions
