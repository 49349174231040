import React from "react"
import _ from 'lodash'

const AvailableActivitiesList = props => {
  const [search, setSearch] = React.useState('')

  let items = props.availableActivities || []
  if (_.trim(search).length > 0) {
    const cleanSearch = _.trim(search).toLowerCase()
    items = items.filter(el => el.name.toLowerCase().indexOf(cleanSearch) > -1)
  }

  const serializeItems = (items).map((item, ix) => (
    <tr key={item.name}>
      <td>
        <i
          onClick={() => props.addToSelected(item)}
          className="btn btn-primary btn-sm"
          style={{  lineHeight: 0,
            padding: '5px 6px',
            margin: 2,
            height: 24,
            width: 24 }}
          title="Add to collection"
        >
          <span
            className="fa fa-plus"
            aria-hidden="true"
            style={{ height: 10, width: 10, paddingRight: 4, paddingTop: 2 }}
          />
        </i>
        {
          props.gameSwapsEnabled &&
          <i
            onClick={() => props.addToSwap(item)}
            style={{ lineHeight: 0, padding: '5px 5px', margin: 2 }}
            className="btn btn-primary btn-sm"
            title="Add as Swap Activity"
          >
            <span className="fa fa-flag-checkered" aria-hidden="true" />
          </i>
        }
      </td>

      <td>{item.name}</td>
      <td>{item.description}</td>
      <td>{item.activity_type}</td>
      <td>{item.skippable_by_default ? "Yes" : "No"}</td>
    </tr>
  ))

  return (
    <React.Fragment>
      <div className="row">
        <span className="col-md-8">
          <h4 className="sub-title" style={{ display: "inline-block" }}>
            Available Activities
          </h4>
        </span>
        <span className="col-md-4" style={{ marginRight: -1, paddingRight: 45 }}>
          <input type="text" className="form-control" placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} />
        </span>
      </div>
      <div className="row col-md-12">
        <table
          className="table table-condensed table-striped w-100 d-block d-md-table"
          id="selected_activities_list"
        >
          <thead>
            <tr>
              <th />
              <th>Name</th>
              <th>Description</th>
              <th>Type</th>
              <th>Skippable by Default</th>
            </tr>
          </thead>
          <tbody>{serializeItems}</tbody>
        </table>
      </div>
    </React.Fragment>
  )
}

export default AvailableActivitiesList
