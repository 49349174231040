import Rails from 'rails-ujs'
import 'bootstrap/dist/js/bootstrap'
import $ from 'jquery-migrate'
import 'data-confirm-modal'

// used to be in application.js, but caused a weird double bootstrap import bug
// with logout button
import '../src/shared/classroom_view'

import 'select2';

import '../src/styles/shared.scss'

window.$ = $;
window.jQuery = $;


Rails.start();

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

$(function() {
  const {version,browser} = DetectBrowser.detect()
  const incompatibleFirefox = ((browser === "Firefox") && (version <= 20));
  const incompatibleIe = ((browser === "Explorer") && (version < 9));
  if (incompatibleFirefox || incompatibleIe) {
     window.location = "/browser_not_supported";
  }
})

$(document).ready(() => {
  $('select.select2').each(function() {
    $(this).select2();
  })
})
