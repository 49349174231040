import React from "react"
import { String, Select } from "../form/inputs"
import QuestionOptions from "./QuestionOptions"
import { get } from "lodash"

const Question = props => {
  const name = name => props.namePrefix + "[" + name + "]"

  const updateType = e => {
    const { value } = e.target
    const shouldReset =
      (props.question.type === "emoji" && value !== "emoji") ||
      (props.question.type !== "emoji" && value === "emoji")
    const data = {
      ...props.question,
      type: value,
      options: shouldReset ? [] : props.question.options
    }
    props.updateQuestion(props.index, data)
  }

  const updateQuestion = e => {
    const { value } = e.target
    const data = { ...props.question, question: value }
    props.updateQuestion(props.index, data)
  }

  const updateOptions = (index, value) => {
    const options = Array.from(get(props.question, "options", []))
    options[index] = value
    const data = { ...props.question, options }
    props.updateQuestion(props.index, data)
  }

  const removeOption = index => {
    const options = Array.from(get(props.question, "options", []))
    options.splice(index, 1)
    const data = { ...props.question, options }
    props.updateQuestion(props.index, data)
  }

  const addOption = () => {
    const options = Array.from(get(props.question, "options", []))
    options.push("")
    const data = { ...props.question, options }
    props.updateQuestion(props.index, data)
  }

  const questionTypes = [
    { value: "text", label: "Text" },
    { value: "select", label: "Select" },
    { value: "multi_select", label: "Multi select" },
    { value: "emoji", label: "Emoji" }
  ]

  return (
    <div className="question-subform" id={"questionSubform" + props.index}>
      <a
        onClick={() => removeOption(props.index)}
        className="btn btn-link pull-right"
      >
        &times;
      </a>

      <String
        label="Question"
        value={props.question.question || ""}
        onChange={updateQuestion}
        name={name("question")}
        inputId={"questionQuestion" + props.index}
      />

      <Select
        label="Type"
        name={name("type")}
        value={props.question.type}
        onChange={updateType}
        options={questionTypes}
        inputId={"questionType" + props.index}
      />

      <QuestionOptions
        questionType={props.question.type}
        questionIndex={props.index}
        namePrefix={name("options")}
        options={props.question.options}
        onChange={updateOptions}
        onRemoveOption={removeOption}
        onAddOption={addOption}
      />
    </div>
  )
}

export default Question
