import React from "react"
import QuestionInput from "./QuestionInput"
import RailsForm from "../form/RailsForm"

const Questionnaire = props => {
  const onSubmit = e => {
    if (!props.action) {
      e.preventDefault()
      if (typeof props.onSubmit === "function") {
        props.onSubmit()
      }
    }
  }

  const questionsInputs = props.questions.map((question, index) => (
    <QuestionInput
      key={"questionInput" + index}
      index={index}
      question={question}
    />
  ))

  return (
    <RailsForm action={props.action} method={props.method} onSubmit={onSubmit}>
      {questionsInputs}
      <button disabled={props.isPreview} className="btn btn-primary">
        Submit
      </button>
    </RailsForm>
  )
}

export default Questionnaire
