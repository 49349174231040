import React from "react"
import { String } from "../form/inputs"

const QuestionOption = props => (
  <div className="input-with-remove-button">
    <String
      label={false}
      error={props.error}
      name={props.name}
      onChange={props.onChange}
      value={props.value}
    />
    <a onClick={props.onRemove} className="btn btn-link pull-right">
      &times;
    </a>
  </div>
)

export default QuestionOption
