import React from "react"
import { Hidden, File, String } from "../form/inputs"

const QuestionEmojiOption = props => {
  const [imageData, setImageData] = React.useState(props.value.image_data)
  const [emoji_id, setEmojiId] = React.useState(props.value.emoji_id)
  const [initFileName, setInitialFileName] = React.useState(
    Boolean(props.value.image_data)
  )

  const onFileChange = file_data => {
    setImageData(file_data)
    setEmojiId('')
    setInitialFileName(false)
    props.onImageChange(file_data)
  }

  const emojiExists = emoji_id && emoji_id !== ""
  const fileName = props.value.image_file_name?.original_filename || props.value.image_file_name

  return (
    <div className="input-with-remove-button">
      {emojiExists && (
        <Hidden
          type="hidden"
          id="emoji_id"
          value={emoji_id}
          name={props.name + "[emoji_id]"}
          disabled={props.disabled}
        />
      )}

      {!emojiExists && (<>
        <Hidden
          type="hidden"
          id="file_data"
          value={props.value.file_data || imageData}
          name={props.name + "[image_data]"}
          disabled={props.disabled}
          />

        <Hidden
          type="hidden"
          id="file_name"
          value={fileName}
          name={props.name + "[image_file_name]"}
          disabled={!fileName || props.disabled}
          />
        </>)}

      <File
        label="Image"
        inputId={props.name + "[image_file_name]"}
        image={props.value.image}
        error={props.error ? props.error.image_file_name : null}
        name={props.name + "[image_file_name]"}
        onChange={onFileChange}
        disabled={props.disabled}
      />

      <String
        label="Display Name"
        error={props.error ? props.error.display_name : null}
        inputId={props.name + "[display_name]"}
        name={props.name + "[display_name]"}
        onChange={props.onChange}
        value={props.value.display_name}
        disabled={props.disabled}
      />

      <a
        onClick={props.onRemove}
        className={"btn btn-link pull-right " + props.disabledLinkClass}
      >
        &times;
      </a>
      <hr />
    </div>
  )
}

export default QuestionEmojiOption
