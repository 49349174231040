import React from "react"
import { EmojiOption, Group } from "./inputs"

const Emoji = props => {
  const { label, inputId, name, options, ...other } = props
  const inputName = props.name || inputId + "[]"

  const groupOptions = options.map((option, index) => (
    <EmojiOption
      id={inputId + "_" + index}
      name={inputName}
      key={inputId + "option" + index}
      value={index}
      defaultChecked={index === parseInt(props.checkedIndex)}
      label={option.display_name}
      image_data={option.file_data}
      image_url={option.image ? option.image.urls.thumb : null}
      file_name={option.file_name}
    />
  ))

  return (
    <Group label={label} inputId={inputId}>
      <div className="emoji-options">{groupOptions}</div>
    </Group>
  )
}

export default Emoji
