import React from "react"
import CollectionItem from "./CollectionItem"
import _ from "lodash"

const CollectionItemsSelector = props => {
  const [visibleItems, setVisibleItems] = React.useState(props.available_items)
  const [searchValue, setSearchValue] = React.useState("")

  const displayedCollectionName =
    props.displayed_collection_name || props.collection_name
  const displayedItemName = props.displayed_item_name || props.item_name
  const title = "Add " + displayedItemName + " to " + displayedCollectionName

  const handleSearch = e => {
    e.preventDefault()
    if (props.search_path) {
      var q = {}
      q[props.search_field] = searchValue
      $.ajax({
        url: props.search_path,
        method: "GET",
        format: "json",
        data: $.param({ q }),
        dataType: "json",
        success: data => {
          setVisibleItems(
            _.chain(data)
              .values()
              .first()
              .value()
          )
        }
      })
    } else {
      if (searchValue === "") {
        setVisibleItems(props.available_items)
      } else {
        const newItems = _.filter(props.available_items, item => {
          for (let method of props.search_methods) {
            if (typeof item[method] === "string") {
              if (item[method].match(new RegExp(searchValue, "i")) !== null) {
                return true
              }
            }
          }
          return false
        })
        setVisibleItems(newItems)
      }
    }
  }

  const labelMethods = () =>
    props.label_method
      ? Array.isArray(props.label_method)
        ? props.label_method
        : [props.label_method]
      : []

  const items = visibleItems.map((item, index) => (
    <CollectionItem
      key={index}
      item_name={props.item_name}
      collection_name={props.collection_name}
      displayedItemName={displayedItemName}
      displayedCollectionName={displayedCollectionName}
      item={item}
      label={labelMethods().reduce((acc, val) => (acc += item[val]), "")}
      save_path={props.save_path}
    />
  ))

  return (
    <div className="form-search">
      <h3 className="sub-title">{title}</h3>

      <input
        placeholder="Search"
        onChange={e => setSearchValue(e.target.value)}
        value={searchValue || ""}
        className="form-control"
        type="search"
        id="query"
      />
      <button onClick={handleSearch} type="submit" className="btn btn-default">
        Search
      </button>
      <br />
      <br />

      <ul id="items_list">{items}</ul>
    </div>
  )
}

export default CollectionItemsSelector

// var CollectionItemsSelector = React.createClass({
//   getInitialState: function() {
//     return {
//       visible_items: this.props.available_items,
//       searchValue: ""
//     }
//   },

//   componentWillMount: function() {
//     CollectionItemStore.on(
//       "collection_item.created",
//       this.onCollectionItemCreated
//     )
//   },

//   componentDidMount: function() {
//     CollectionItemStore.setCollectionId(this.props.collection_id)
//     CollectionItemStore.setSavePath(this.props.save_path)
//     CollectionItemStore.setItemName(this.props.item_name)
//     CollectionItemStore.setCollectionName(this.props.collection_name)
//   },

//   componentWillUnmount: function() {
//     CollectionItemStore.off(
//       "collection_item.created",
//       this.onCollectionItemCreated
//     )
//   },

//   onCollectionItemCreated: function() {
//     location.reload()
//   },

//   _getItem: function(item_id, items) {
//     return items.filter(function(s) {
//       return s.id == item_id
//     })[0]
//   },

//   getItemFromAvailable: function(item_id) {
//     return this._getItem(item_id, this.state.availableItems)
//   },

//   handleSearchValueChange: function(e) {
//     this.setState({ searchValue: e.target.value })
//   },

//   handleSearch: function(e) {
//     e.preventDefault()
//     if (this.props.search_path) {
//       var q = {}
//       q[this.props.search_field] = this.state.searchValue
//       $.ajax({
//         url: this.props.search_path,
//         method: "GET",
//         format: "json",
//         data: $.param({ q }),
//         dataType: "json",
//         success: function(data) {
//           this.setState({
//             visible_items: _.chain(data)
//               .values()
//               .first()
//               .value()
//           })
//         }.bind(this)
//       })
//     } else {
//       if (this.state.searchValue === "") {
//         this.setState({ visible_items: this.props.available_items })
//       } else {
//         this.setState({
//           visible_items: _.filter(
//             this.props.available_items,
//             function(item) {
//               for (let method of this.props.search_methods) {
//                 if (typeof item[method] === "string") {
//                   if (
//                     item[method].match(
//                       new RegExp(this.state.searchValue, "i")
//                     ) !== null
//                   ) {
//                     return true
//                   }
//                 }
//               }
//               return false
//             },
//             this
//           )
//         })
//       }
//     }
//   },

//   handleReset: function() {
//     $("#query").val("")
//     this.handleSearch()
//     this.setState({ showReset: false })
//   },

//   handleClickSelectItem: function(item_id, event) {
//     event.preventDefault()

//     CollectionItemActions.addCollectionItem(item_id)
//   },

//   render: function() {
//     var displayedCollectionName =
//       this.props.displayed_collection_name || this.props.collection_name
//     var displayedItemName =
//       this.props.displayed_item_name || this.props.item_name
//     var title = "Add " + displayedItemName + " to " + displayedCollectionName

//     var labelMethods = function() {
//       if (this.props.label_method instanceof Array) {
//         return this.props.label_method
//       } else {
//         return [this.props.label_method]
//       }
//     }.bind(this)()

//     var items = []

//     $.each(
//       this.state.visible_items,
//       function(index, item) {
//         var label = ""
//         labelMethods.map(function(key) {
//           label += item[key] + " "
//         })

//         items.push(
//           <CollectionItem
//             key={index}
//             item_name={this.props.item_name}
//             collection_name={this.props.collection_name}
//             displayedItemName={displayedItemName}
//             displayedCollectionName={displayedCollectionName}
//             item={item}
//             label={label}
//             save_path={this.props.save_path}
//           />
//         )
//       }.bind(this)
//     )

//     return (
//       <div>
//         <h3 className="sub-title">{title}</h3>
//         <form
//           onSubmit={this.handleSearch}
//           className="form-inline form-search"
//           id="item_search"
//           action={this.props.search_path}
//           acceptCharset="UTF-8"
//           method="get"
//         >
//           <input
//             placeholder="Search"
//             onChange={this.handleSearchValueChange}
//             value={this.state.searchValue}
//             className="form-control"
//             type="search"
//             id="query"
//           />
//           <button className="btn btn-info">Search</button>
//         </form>
//         <br />
//         <br />

//         <ul id="items_list">{items}</ul>
//       </div>
//     )
//   }
// })
