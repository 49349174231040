/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

const classroomView = function(el) {
  const REFRESH_BUTTON_SELECTOR = '#refresh_classroom';
  const FEED_SELECTOR = '#feed';
  const SHOW_DETAILS_BUTTON_SELECTOR = '#button_all_students_details';
  const COLLAPSE_SELECTOR = '.collapse';
  const TOGGLE_BTN_SELECTOR = '.toggleall';
  const PANEL_COLLAPSE_SELECTOR = '.panel_collapse';
  const HIDE_ALL_DETAILS_TEXT = 'Hide All Details';
  const SHOW_ALL_DETAILS_TEXT = 'Show All Details';
  const HIDE_DETAILS_TEXT = 'Hide Details';
  const SHOW_DETAILS_TEXT = 'Show Details';
  const REFRESH_BUTTON_TEXT = 'Refresh Classroom';
  const REFRESHING_IN_PROGRESS_TEXT = 'Refreshing Classroom...';

  const $el = $(el);
  const $refreshClassroomBtn = $(REFRESH_BUTTON_SELECTOR);
  const $feed = $(FEED_SELECTOR);
  const $showDetailsBtn = $(SHOW_DETAILS_BUTTON_SELECTOR);
  const $toggleAllBtn = $(TOGGLE_BTN_SELECTOR);

  const markRefreshingAsInProgress = function() {
    ($refreshClassroomBtn.blur)();
    $refreshClassroomBtn.text(REFRESHING_IN_PROGRESS_TEXT);
    return $refreshClassroomBtn.attr('disabled', true);
  };

  const refreshCallback = function(event) {
    $feed.html(event.detail[0].responseText);
    $refreshClassroomBtn.text(REFRESH_BUTTON_TEXT);
    $refreshClassroomBtn.attr('disabled', false);
    $("[id^='button_student_details']").on('click', onToggleIndividualClick)
    return $showDetailsBtn.html(SHOW_ALL_DETAILS_TEXT);
  };

  const onShowSignleDetails = function() {
    const $button = $(`#button_${this.id}`);
    $button.html(HIDE_DETAILS_TEXT);
    if (!$(`a:contains('${SHOW_DETAILS_TEXT}')`).length) {
      return $showDetailsBtn.html(HIDE_ALL_DETAILS_TEXT);
    }
  };

  const onHideSignleDetails = function(e) {
    const $button = $(`#button_${this.id}`);
    $button.html(SHOW_DETAILS_TEXT);
    if (!$(`a:contains('${HIDE_DETAILS_TEXT}')`).length) {
      return $showDetailsBtn.html(SHOW_ALL_DETAILS_TEXT);
    }
  };

  const onToggleIndividualClick = function(e) {
    e.preventDefault();
    const btn = $(e.target)
    const id = e.target.id
    if (btn.html() === SHOW_DETAILS_TEXT) {
      btn.html(HIDE_DETAILS_TEXT);
      $('#' + id.replace('button_','')).collapse('show');
    } else {
      btn.html(SHOW_DETAILS_TEXT);
      $('#' + id.replace('button_','')).collapse('hide');
    }
  }

  const onToggleClick = function(e) {
    e.preventDefault();
    if ($showDetailsBtn.html() === SHOW_ALL_DETAILS_TEXT) {
      $showDetailsBtn.html(HIDE_ALL_DETAILS_TEXT);
      return $el.find(PANEL_COLLAPSE_SELECTOR).collapse('show');
    } else {
      $showDetailsBtn.html(SHOW_ALL_DETAILS_TEXT);
      return $el.find(PANEL_COLLAPSE_SELECTOR).collapse('hide');
    }
  };

  $refreshClassroomBtn.on('ajax:before', markRefreshingAsInProgress);
  $refreshClassroomBtn.on('ajax:complete', refreshCallback);

  $el.on('show.bs.collapse', COLLAPSE_SELECTOR, onShowSignleDetails);
  $el.on('hidden.bs.collapse', COLLAPSE_SELECTOR, onHideSignleDetails);

  $("[id^='button_student_details']").on('click', onToggleIndividualClick)
  return $toggleAllBtn.on('click', onToggleClick);
};

$(function() {
  const classroomElement = document.getElementById('classrooms_show');
  if (classroomElement) { return classroomView(classroomElement); }
});
