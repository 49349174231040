import React from "react"

const SelectedProgramRow = props => {
  const removeProgram = e => {
    e.preventDefault()
    e.stopPropagation()
    props.onRemoveProgram(props.data)
  }

  return (
    <tr>
      <td>
        <input
          type="hidden"
          name="student_program[][program_id]"
          value={props.data.id}
        />
        <input
          min="1"
          defaultValue={1}
          style={{ width: "70px" }}
          className="numeric integer optional form-control"
          type="number"
          step="1"
          name="student_program[][repetitions]"
        />
      </td>

      <td>{props.data.name}</td>

      <td>{props.data.description}</td>

      {!props.hideSchools && (<td>
        <ul>
          {props.data.schools.map((schoolName, index) => (
            <li key={index}>{schoolName}</li>
          ))}
        </ul>
        </td>)}

      <td>
        <a href="#" onClick={removeProgram} title="Remove program">
          <span className="fa fa-trash-o" styles="color: #555" />
        </a>
      </td>
    </tr>
  )
}

export default SelectedProgramRow
