import React from "react"
import PropTypes from "prop-types"

const LinkEdit = props => {
  const css_id = "edit_" + props.itemName + "_" + props.item.id

  return (
    <a href={props.path} title="Edit" className="action-button" id={css_id}>
      <span className="fa fa-pencil" />
    </a>
  )
}

LinkEdit.propTypes = {
  item: React.PropTypes.object.isRequired,
  path: React.PropTypes.string.isRequired,
  itemName: React.PropTypes.string
}

export default LinkEdit
