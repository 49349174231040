import React from "react";
import PropTypes from "prop-types";
import Modal from "../Modal";

const GameSwap = (props) => {
  const SWAP_MODAL_BODY = (
    <p>
      To finish this game and exchange it for the next available Game Swap in
      the student's program click the SWAP button.
      <br /> <br />
      This change will be applied to all future TCI sessions.
    </p>
  );
  const SECONDARY_SWAP_MODAL_BODY = (
    <p>
      You are about to update a student's program by replacing this game with
      the next available Game Swap.{" "}
      <span style={{ fontWeight: "bold" }}>
        This change will be applied to all future TCI sessions.
      </span>
      <br />
      <br />
      If you want to skip this game for today's session only click Cancel and
      use Skip instead.
    </p>
  );

  const [showModal, setShowModal] = React.useState(false);
  const [modalBody, setModalBody] = React.useState(null);
  const [modalActions, setModalActions] = React.useState(null);
  const [modalAlert, setModalAlert] = React.useState(null);
  const sendRequest = (options) => {
    $.ajax({
      url: options.path,
      method: options.method,
      dataType: "JSON",
      data: options.params,
      cache: false,
      success: options.success,
      error: options.error,
    });
  };

  const onSwapClick = (e, program_activity_state_id) => {
    e.preventDefault();
    setModalBody(SWAP_MODAL_BODY);
    setModalActions([
      { title: "Cancel", callback: () => setShowModal(false) },
      {
        title: "Swap",
        callback: () => {
          onSecondarySwapClick(e, program_activity_state_id);
        },
      },
    ]);
    setShowModal(true);
  };

  const onSecondarySwapClick = (e, program_activity_state_id) => {
    e.preventDefault();
    setModalBody(SECONDARY_SWAP_MODAL_BODY);
    setModalActions([
      {
        title: "Cancel",
        callback: () => {
          setShowModal(false);
          setModalAlert(null);
        },
      },
      {
        title: "Confirm",
        callback: () => {
          sendRequest({
            path: props.collectionPath + "/swap_state",
            method: "PUT",
            params: {
              spas_id: program_activity_state_id,
              student_program_id: props.student_program_id,
            },
            success: () => window.location.reload(),
            error: (r, e) => {
              setModalAlert(r.responseJSON.message);
            },
          });
        },
      },
    ]);
    setShowModal(true);
  };

  return (
    <React.Fragment>
      <button
        onClick={(e) => onSwapClick(e, props.program_activity_state_id)}
        style={{ marginBottom: 2, marginTop: 2, marginLeft: 23 }}
        className={`btn btn-primary btn-sm ${props.unSkip ? '' : 'small_left_space'}`}
      >
        Swap
      </button>
      <Modal
        show={showModal}
        actions={modalActions}
        body={modalBody}
        onCancel={() => setShowModal(false)}
        title="Are you sure?"
        alertMessage={modalAlert}
      />
    </React.Fragment>
  );
};

export default GameSwap;
