import React from 'react'
import SelectedPrograms from './SelectedPrograms'
import AvailablePrograms from './AvailablePrograms'
import $ from 'jquery-migrate'

const New = (props) => {
  const [selectedPrograms, setSelectedPrograms] = React.useState([])
  const [availablePrograms, setAvailablePrograms] = React.useState(
    props.available_programs
  )
  const [searchValue, setSearchValue] = React.useState('')
  const [showReset, setShowReset] = React.useState(false)
  const [sortValue, setSortValue] = React.useState('name asc')

  const unselectProgram = (program) => {
    var index = selectedPrograms.indexOf(program)
    if (index > -1) {
      var programs = [].concat(selectedPrograms)
      programs.splice(index, 1)
      setSelectedPrograms(programs)
    }
  }

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      handleSearch()
    }
  }

  const handleSearch = () => {
    setShowReset(!!searchValue)
    searchPrograms()
  }

  const searchPrograms = (newValue) => {
    const search_values =
      newValue === ''
        ? {}
        : {
            name_or_description_or_schools_name_cont: searchValue,
            s: sortValue
          }

    // FIXME: Sort does not appear to persist when performing a new search.
    $.ajax({
      url: props.available_programs_path,
      method: 'GET',
      format: 'json',
      data: $.param({
        q: search_values
      }),
      dataType: 'json',
      success: (data) => {
        setAvailablePrograms(data['data'])
      }
    })
  }

  const handleReset = () => {
    searchPrograms('')
    setSearchValue('')
    setShowReset(false)
    this.searchPrograms()
    setSortValue('name asc')
  }

  const sortPrograms = (sortValue) => {
    setSortValue(sortValue)
    this.searchPrograms()
  }

  return (
    <div>
      <SelectedPrograms
        data={selectedPrograms}
        hideSchools={props.hide_schools}
        onRemoveProgram={unselectProgram}
      />

      <h4 className="sub-title" style={{ display: 'inline-block' }}>
        Available Programs
      </h4>
      <div class="pull-right">
        <div className="form-inline form-search" id="programs_search">
          <input
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyPress={onKeyPress}
            className="form-control"
            type="search"
          />
          <a onClick={handleSearch} className="btn btn-primary">
            Search
          </a>
        </div>
        {showReset ? (
          <a onClick={handleReset} className="btn btn-danger left_space">
            Reset
          </a>
        ) : null}
      </div>

      <div className="row">
        <AvailablePrograms
          data={availablePrograms}
          hideSchools={props.hide_schools}
          onSortLinkClick={sortPrograms}
          selectProgram={(p) => setSelectedPrograms([...selectedPrograms, p])}
          sortValue={sortValue}
        />
      </div>
    </div>
  )
}

export default New
