import React from "react"
import PropTypes from "prop-types"

const LinkDestroy = props => {
  let css_id = "destroy_" + props.itemName + "_" + props.item.id

  const extra_props = props.onClick
    ? { href: props.path, onClick: props.onClick }
    : {
        href: props.path,
        "data-method": "delete",
        "data-confirm": "Are you sure?"
      }

  return (
    <a
      title={props.title}
      {...extra_props}
      className="action-button"
      id={css_id}
      rel="nofollow"
    >
      <span className="fa fa-trash-o" style={{ color: "#555" }} />
    </a>
  )
}

LinkDestroy.propTypes = {
  path: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired
}

export default LinkDestroy
