import React from "react"

const ActivityDirectionsData = props => {
  const errorClassDirections = props.directionsError ? "has-error" : ""
  const errorDirections = (
    <span
      className="help-block"
      dangerouslySetInnerHTML={{ __html: props.directionsError }}
    />
  )

  const errorClassActivityLink = props.activityLinkError ? "has-error" : ""
  const errorActivityLink = (
    <span
      className="help-block"
      dangerouslySetInnerHTML={{ __html: props.activityLinkError }}
    />
  )

  const [isActivityLinkActive, setIsActivityLinkActive] = React.useState(props.activity?.activity_link ? true : false)

  const activityLinkDropDownChange = e => {
    if (e.target.value == "") {
      setIsActivityLinkActive(false)
    } else {
      setIsActivityLinkActive(true)
    }
  }

  const activityLinkTextInput = (
    <input
      className="string optional form-control"
      type="text"
      name="activity[activity_link_2]"
      defaultValue={props.activity?.activity_link?.includes('://') ? props.activity?.activity_link?.split('://')[1] : props.activity?.activity_link}
      id="activity_activity_link"
      placeholder="e.g. www.example.com"
    />
  )

  return (
    <div id="data-activity-directions-form-fields">
      <h3 className="sub-title">Activity directions</h3>

      <div className={"form-group text optional " + errorClassDirections}>
        <label
          className="text optional control-label"
          htmlFor="activity_directions"
        >
          Directions
        </label>
        <textarea
          className="text optional form-control"
          name="activity[directions]"
          id="activity_directions"
          defaultValue={props.activity?.directions}
        />
        {errorDirections}
      </div>

      <div className={"form-group string optional " + errorClassActivityLink}>
        <label
          className="string optional control-label"
          htmlFor="activity_activity_link"
        >
          Activity Link
        </label>
        <select
          className="string optional form-control"
          name="activity[activity_link_1]"
          defaultValue={props.activity?.activity_link?.includes('://') ? props.activity?.activity_link?.split('://')[0] : ""}
          id="activity_activity_link"
          onChange={activityLinkDropDownChange}
        >
          <option value="">No Activity Link</option>
          <option value="http">Use http</option>
          <option value="https">Use https</option>
        </select>
        {isActivityLinkActive ? activityLinkTextInput : ""}
        {errorActivityLink}
      </div>

      <hr />
    </div>
  )
}

export default ActivityDirectionsData
