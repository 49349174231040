import React from "react"
import { ButtonToolbar, Button } from "react-bootstrap"
import Modal from "./Modal"

const ActivityStatusSwitch = props => {
  const [isOpen, setIsOpen] = React.useState(props.current_status)
  const [showModal, setModal] = React.useState(false)

  React.useEffect(() => {
    console.log("status has changed")
    console.log("status", props.current_status)
  }, [props.current_status])

  const handleAjaxFail = data => {
    if (data.status == 401) {
      window.location.reload()
    } else if (data.status === 400) {
      window.alert("Classroom isn't configured for TCI")
    } else if (data.status === 409) {
      window.alert(
        "Classroom's state has changed since you've loaded the page. Please refresh the page and try again"
      )
    } else {
      window.alert("Something went wrong. Please try again later.")
    }
  }

  const handleAjaxSuccessAfterClose = () => {
    setModal(false)
    setIsOpen(false)
  }

  const handleAjaxSuccessAfterOpen = () => {
    setModal(false)
    setIsOpen(true)
  }

  const modalActions = [
    {
      title: "Yes",
      callback: () => {
        console.log("closing classroom with closing programs")
        $.ajax({
          url: props.close_path,
          method: "PUT",
          data: { close_programs: true },
          statusCode: {
            200: handleAjaxSuccessAfterClose,
            201: handleAjaxSuccessAfterClose,
            400: handleAjaxFail,
            401: handleAjaxFail,
            409: handleAjaxFail
          }
        })
      }
    },
    {
      title: "No",
      callback: () => {
        console.log("closing classroom without closing programs")
        $.ajax({
          url: props.close_path,
          method: "PUT",
          statusCode: {
            200: handleAjaxSuccessAfterClose,
            201: handleAjaxSuccessAfterClose,
            400: handleAjaxFail,
            401: handleAjaxFail,
            409: handleAjaxFail
          }
        })
      }
    },
    {
      title: "Cancel",
      callback: () => {
        console.log("cancelling modal")
        setModal(false)
      }
    }
  ]

  const handleClick = () => {
    if (isOpen) {
      setModal(true)
    } else {
      $.ajax({
        url: props.open_path,
        method: "PUT",
        statusCode: {
          200: handleAjaxSuccessAfterOpen,
          201: handleAjaxSuccessAfterOpen,
          400: handleAjaxFail,
          401: handleAjaxFail,
          409: handleAjaxFail
        }
      })
    }
  }

  const RadioButton = (
    <a href="#" onClick={handleClick}>
      <i
        id="switch"
        className={
          isOpen
            ? "fa fa-dot-circle-o text-success"
            : "fa fa-circle-o text-danger"
        }
      />
    </a>
  )

  const SwitchButton = (
    <div>
      <div>
        <h3>Classroom Status: {isOpen ? "Open" : "Closed"}</h3>
      </div>
      <ButtonToolbar style={{ float: 'right' }}>
        <Button
          bsSize="large"
          style={{ margin: 5 }}
          onClick={handleClick}
          disabled={isOpen}
          bsStyle="primary"
        >
          Open
        </Button>
        <Button
          bsSize="large"
          style={{ margin: 5 }}
          onClick={handleClick}
          disabled={!isOpen}
          bsStyle="danger"
        >
          Close
        </Button>
      </ButtonToolbar>
    </div>
  )

  return (
    <div>
      {props.switch_type === "radio" ? RadioButton : SwitchButton}
      <Modal
        show={showModal}
        title="Are you sure?"
        body="Do you want to complete all the remaining activities in the student's programs?"
        onCancel={() => setModal(false)}
        order
        actions={modalActions}
        cancelCopy="Cancel"
        backdrop="static"
      />
    </div>
  )
}

export default ActivityStatusSwitch
