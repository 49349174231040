import React from "react"
import PropTypes from "prop-types"

const SchoolCustomAttributesRow = props => {
  const htmlIdFor = fieldName => `custom_attributes_${fieldName}_${props.index}`
  const { data } = props

  return (
    <div className="row">
      <div className="col-xs-5">
        <div className="form-group string required">
          <label
            className="string required control-label"
            htmlFor={htmlIdFor("id")}
          >
            <abbr title="required">*</abbr> Unique identifier
          </label>
          <input
            name="school[custom_attributes][][id]"
            id={htmlIdFor("id")}
            className="string required form-control"
            value={data.id}
            onChange={e =>
              props.setAttribute(props.index, "id", e.target.value)
            }
          />
        </div>
      </div>

      <div className="col-xs-6">
        <div className="form-group string required">
          <label
            className="string required control-label"
            htmlFor={htmlIdFor("label")}
          >
            <abbr title="required">*</abbr> Label
          </label>
          <input
            name="school[custom_attributes][][label]"
            id={htmlIdFor("label")}
            className="string required form-control"
            value={data.label}
            onChange={e =>
              props.setAttribute(props.index, "label", e.target.value)
            }
          />
        </div>
      </div>

      <div className="col-xs-1">
        <div className="form-group string">
          <div className="school-custom-attributes-controls">
            <a
              href="#"
              id={htmlIdFor("delete")}
              onClick={e => props.onDelete(props.index, e)}
            >
              <i className="fa fa-trash" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

SchoolCustomAttributesRow.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default SchoolCustomAttributesRow
