import React from "react"
import { Select } from "../form/inputs"
import ActivityAttributeOptions from "./ActivityAttributeOptions"

const ActivityOnSiteAttributes = props => {
  const [withAnswer, setWithAnswer] = React.useState(Boolean(props.answer))
  const addAnswer = () => setWithAnswer(true)
  const removeAnswer = () => setWithAnswer(false)
  const name = fieldName => "activity[data][attributes][][" + fieldName + "]"
  const answer = () => (
    <div>
      <div className="form-group string">
        <label
          className="string control-label"
          htmlFor={props.htmlIdFor("answer")}
        >
          Answer
        </label>

        <div className="input-with-remove-button">
          <div className="input-container">
            <input
              name="activity[data][attributes][][answer]"
              id={props.htmlIdFor("answer")}
              className="string form-control"
              value={props.attribute.answer || ""}
              onChange={e =>
                props.onChange(props.index, "answer", e.target.value)
              }
              disabled={props.disabled}
            />
          </div>
          <a
            onClick={removeAnswer}
            className={"btn btn-link pull-right " + props.disabledLinkClass}
          >
            &times;
          </a>
        </div>
      </div>
    </div>
  )

  var options
  var questionTypes = [
    { value: "text", label: "Text" },
    { value: "select", label: "Select" },
    { value: "multi_select", label: "Multi select" },
    { value: "emoji", label: "Emoji" }
  ]

  if (["select", "multi_select", "emoji"].indexOf(props.type) >= 0) {
    options = (
      <ActivityAttributeOptions
        type={props.type}
        attribute={props.attribute}
        index={props.index}
        onChange={props.onChange}
        namePrefix={name("options")}
        disabled={props.disabled}
        disabledLinkClass={props.disabledLinkClass}
      />
    )
  }

  return (
    <div className="col-xs-5">
      <div className="form-group string required">
        <label
          className="string required control-label"
          htmlFor={props.htmlIdFor("label")}
        >
          <abbr title="required">*</abbr> Label
        </label>

        <input
          name={name("label")}
          id={props.htmlIdFor("label")}
          className="string required form-control"
          value={props.attribute.label || ""}
          disabled={props.disabled}
          onChange={e => props.onChange(props.index, "label", e.target.value)}
        />

        <Select
          name={name("type")}
          inputId={props.htmlIdFor("type")}
          label="Type"
          value={props.type}
          options={questionTypes}
          disabled={props.disabled}
          onChange={e => props.onChange(props.index, "type", e.target.value)}
        />
        {options}
      </div>

      {props.type === "text" && answer()}
    </div>
  )
}

export default ActivityOnSiteAttributes
