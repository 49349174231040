import React from "react"
import SelectedProgramRow from "./SelectedProgramRow"

const SelectedPrograms = props => {
  const renderRows = props.data.map((program, index) => (
    <SelectedProgramRow
      data={program}
      index={index}
      hideSchools={props.hideSchools}
      key={index}
      onRemoveProgram={props.onRemoveProgram}
    />
  ))

  return (
    <table
      className="table table-condensed table-striped"
      id="selected_programs_list"
    >
      <colgroup>
        <col span="1" style={{ width: "80px" }} />
        <col span="1" />
        <col span="1" style={{ width: `${props.hideSchools ? '65%' : '30%' }` }} />
        {!props.hideSchools && (<col span="1" style={{ width: "35%" }} />)}
        <col span="1" style={{ width: "25px" }} />
      </colgroup>

      <thead>
        <tr>
          <th>Repetitions</th>
          <th>Name</th>
          <th>Description</th>
          {!props.hideSchools && (<th>School</th>)}
          <th />
        </tr>
      </thead>

      <tbody>{renderRows}</tbody>
    </table>
  )
}

export default SelectedPrograms
