import React from "react"
import ActivityModal from "./ActivityModal"
import $ from "jquery-migrate"

const Launch = props => {
  const [inputs, setInputs] = React.useState([])
  const [url, setUrl] = React.useState(props.url)
  const [reqInFlight, setReqInFlight] = React.useState(false)
  const [showModal, setModal] = React.useState(false)

  const objectToInput = (
    obj,
    refObj,
    parent,
    propertyChanges,
    objectGraphPath
  ) => {
    if (typeof obj !== "string") {
      for (let property in obj) {
        if (isNaN(property))
          objectGraphPath.push(
            objectGraphPath.length > 0 ? "[" + property + "]" : property
          )
        if (obj[property] != null && obj[property].constructor != Function) {
          if (!refObj[property]) refObj[property] = {}
          objectToInput(
            obj[property],
            refObj[property],
            obj,
            propertyChanges,
            objectGraphPath
          )
        }
        objectGraphPath.pop()
      }
    } else {
      if (obj != refObj) {
        if (parent.constructor === Array) objectGraphPath.push("[]")
        let item = { name: objectGraphPath.join(""), value: obj.toString() }
        propertyChanges.push(item)
      }
    }
  }

  const paramsToForm = obj => {
    let propertyChanges = []
    let objectGraphPath = []
    objectToInput(obj, {}, null, propertyChanges, objectGraphPath)
    return propertyChanges
  }

  const getActivityData = () => {
    if (!reqInFlight) {
      setReqInFlight(true)
      $.get(props.source)
        .success(result => {
          setInputs(paramsToForm(result.params))
          setUrl(result.url)
          setModal(true)
          setReqInFlight(false)
        })
        .error(() => {
          window.location.reload()
        })
    }
  }

  return (
    <div>
      <a className="btn btn-lg btn-launch" onClick={getActivityData}>
        GO
      </a>
      <ActivityModal
        url={url}
        showModal={showModal}
        setModal={setModal}
        inputs={inputs}
      />
    </div>
  )
}

export default Launch
