import React from "react"
import SelectImport from "./Select"
import EmojiImport from "./Emoji"

export const Select = SelectImport
export const Emoji = EmojiImport

export const Group = props => (
  <div className={`form-group ${props.error ? "has-error" : ""}`}>
    {props.label && (
      <label className="control-label" htmlFor={props.inputId}>
        {props.label}
      </label>
    )}
    {props.children}
    {props.error && <span className="help-block">{props.error}</span>}
  </div>
)

export const Csrf = () => {
  var name = $("meta[name='csrf-param']").attr("content")
  var value = $("meta[name='csrf-token']").attr("content")
  return <input name={name} value={value} type="hidden" />
}

export const EmojiOption = props => {
  const { label, id, image_data, image_url, file_name, ...other } = props

  return (
    <div className="emoji-option">
      <label htmlFor={id}>
        <div className="thumbnail">
          <img
            src={image_data || image_url}
            alt={file_name}
            style={{ maxWidth: "100px" }}
          />
          <div className="caption">
            <input
              {...other}
              type="radio"
              style={{ float: "left", marginLeft: "0px", marginRight: "5px" }}
              inputId={id}
            />
            <p>{label}</p>
          </div>
        </div>
      </label>
    </div>
  )
}

export const File = props => {
  const [imageUrl, setImageUrl] = React.useState(
    props.image ? props.image.url || props.image.urls?.medium || null : null
  )
  const [imageFile, setImageFile] = React.useState(
    props.image
      ? { name: props.image.file_name, type: props.image.content_type }
      : null
  )

  const changeFile = e => {
    e.preventDefault()

    var reader = new FileReader()
    var file = e.currentTarget.files[0] || props.imageData

    reader.onloadend = function() {
      setImageUrl(reader.result)
      setImageFile(file)

      if (props.onChange != undefined) {
        props.onChange(reader.result)
      }
    }

    if (file) {
      reader.readAsDataURL(file)
    } else {
      setImageFile(null)
      setImageUrl("")
    }
  }

  const { label, inputId, name, error, ...other } = props

  if (imageFile?.type?.indexOf("image/") > -1 && imageUrl != "") {
    var preview = (
      <div className="thumbnail">
        <img
          src={imageUrl}
          alt={imageFile.name}
          style={{ maxWidth: "300px" }}
        />
      </div>
    )
  }
  return (
    <Group error={error} label={label} inputId={inputId}>
      {preview}

      <input
        {...other}
        type="file"
        className="form-control"
        id={inputId}
        name={name}
        onChange={changeFile}
      />
    </Group>
  )
}

export const Hidden = props => {
  var { defaultValue, inputId, name, error, ...other } = props
  return (
    <input
      {...other}
      type="hidden"
      id={inputId}
      defaultValue={defaultValue}
      name={name}
    />
  )
}

export const SelectOption = props => (
  <option value={props.value}>{props.label}</option>
)

export const String = props => {
  const { label, inputId, name, error, ...other } = props
  return (
    <Group error={error} label={label} inputId={inputId}>
      <input
        {...other}
        type="text"
        className="form-control"
        id={inputId}
        name={name}
      />
    </Group>
  )
}

export const Text = props => {
  const { label, inputId, name, error, ...other } = props
  return (
    <Group error={error} label={label} inputId={inputId}>
      <textarea {...other} className="form-control" id={inputId} name={name} />
    </Group>
  )
}
