import React from "react"
import AvailablePrograms from "./AvailablePrograms"
import SelectedPrograms from "./SelectedPrograms"
import $ from "jquery-migrate"

const Form = props => {
  const [availablePrograms, setAvailablePrograms] = React.useState(
    props.available_programs
  )
  const [selectedPrograms, setSelectedPrograms] = React.useState(
    props.selected_programs
  )
  const [removedPrograms, setRemovedPrograms] = React.useState([])
  const [searchValue, setSearchValue] = React.useState("")
  const [sortValue, setSortValue] = React.useState("name asc")
  const [showReset, setShowReset] = React.useState(false)

  const onKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault()
      e.stopPropagation()
      handleSearch()
    }
  }

  const handleSearch = () => {
    searchPrograms()
    setShowReset(searchValue !== "")
  }

  const handleReset = () => {
    searchPrograms("")
    setSearchValue("")
    setShowReset(false)
    setSortValue("name asc")
  }

  const searchPrograms = (newValue) => {
    const search_values = newValue === "" ? {} : {
      name_or_description_or_schools_name_cont: searchValue,
      s: sortValue
    }

    // FIXME: Sort does not appear to persist when performing a new search.
    $.ajax({
      url: "/admin/programs",
      method: "GET",
      dataType: "json",
      data: $.param({
        q: search_values,
      }),
      dataType: "json",
      success: data => {
        setAvailablePrograms(data["data"])
      }
    })
  }

  const sortPrograms = sortValue => {
    setSortValue(sortValue)
    searchPrograms()
  }

  const addToSelected = item => {
    setSelectedPrograms([...selectedPrograms, item])
  }

  const removeFromSelected = index => {
    const result = Array.from(selectedPrograms || [])
    const [removed] = result.splice(index, 1)
    setSelectedPrograms(result)
    setRemovedPrograms([...removedPrograms, removed])
  }

  const onReorder = (startIndex, endIndex) => {
    const result = Array.from(selectedPrograms || [])
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    setSelectedPrograms(result)
  }

  return (
    <div>
      <h4 className="sub-title" style={{ display: "inline-block" }}>
        Programs
      </h4>

      {props.submit_button && (
        <button className="btn btn-primary pull-right">Submit</button>
      )}

      <SelectedPrograms
        data={selectedPrograms}
        removeFromSelected={removeFromSelected}
        onReorder={onReorder}
      />

      <h4 className="sub-title" style={{ display: "inline-block" }}>
        Available Programs
      </h4>
      <div class="pull-right">
        <div className="form-inline form-search" id="programs_search">
          <input
            placeholder="Search"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            onKeyPress={onKeyPress}
            className="form-control"
            type="search"
          />
          <a onClick={handleSearch} className="btn btn-primary">
            Search
          </a>
        </div>
        {showReset ? (
          <a onClick={handleReset} className="btn btn-danger left_space">
            Reset
          </a>
        ) : null}
      </div>

      <AvailablePrograms
        onSortLinkClick={sortPrograms}
        sortValue={sortValue}
        data={availablePrograms}
        addToSelected={addToSelected}
      />

      {/* this is just a hidden section that adds form elements to destroy */}
      {removedPrograms.map(item => (
        <span key={item.id}>
          <input
            type="hidden"
            value={item.id}
            name="queue_template[queue_template_programs_attributes][][program_id]"
          />
          <input
            type="hidden"
            value="1"
            name="queue_template[queue_template_programs_attributes][][_destroy]"
          />
          <input
            multiple
            type="hidden"
            value={item.program_queue_template_id}
            name="queue_template[queue_template_programs_attributes][][id]"
          />
        </span>
      ))}
    </div>
  )
}

export default Form

// QueueTemplate.Form = React.createClass({
//   getInitialState() {
//     return {
//       availablePrograms: this.props.available_programs,
//       selectedPrograms: this.props.selected_programs,
//       removedPrograms: [],
//       searchValue: "",
//       sortValue: "name asc",
//       showReset: false
//     }
//   },

//   onChange() {
//     this.setState({
//       selectedPrograms: SelectedProgramsStore.getPrograms(),
//       availablePrograms: AvailableProgramsStore.getPrograms(),
//       removedPrograms: RemovedProgramsStore.getPrograms()
//     })
//   },

//   componentWillMount() {
//     SelectedProgramsStore.on("programs.addProgram", this.onChange)
//     SelectedProgramsStore.on("programs.removeProgram", this.onChange)
//     AvailableProgramsStore.on("programs.searchProgram", this.onChange)
//     RemovedProgramsStore.on("programs.addProgram", this.onChange)
//     RemovedProgramsStore.on("programs.removeProgram", this.onChange)
//   },

//   componentDidMount() {
//     SelectedProgramsActions.setPrograms(this.props.selected_programs)
//     AvailableProgramsActions.setPrograms(this.props.available_programs)
//     AvailableProgramsActions.setSearchPath(this.props.search_path)
//   },

//   componentWillUnmount() {
//     SelectedProgramsStore.off("programs.addProgram", this.onChange)
//     SelectedProgramsStore.off("programs.removeProgram", this.onChange)
//     AvailableProgramsStore.off("programs.searchProgram", this.onChange)
//     RemovedProgramsStore.off("programs.addProgram", this.onChange)
//     RemovedProgramsStore.off("programs.removeProgram", this.onChange)
//   },

//   searchPrograms() {
//     AvailableProgramsActions.searchOrSortPrograms({
//       searchValue: this.state.searchValue,
//       sortValue: this.state.sortValue
//     })
//   },

//   handleSearch() {
//     this.searchPrograms()
//     this.setState({ showReset: this.state.searchValue !== "" })
//   },

//   handleReset() {
//     this.setState(
//       {
//         searchValue: "",
//         showReset: false
//       },
//       this.searchPrograms
//     )
//   },

//   updateSearchValue(e) {
//     this.setState({ searchValue: e.target.value })
//   },

//   onKeyPress(e) {
//     if (e.key === "Enter") {
//       e.preventDefault()
//       e.stopPropagation()
//       this.handleSearch()
//     }
//   },

//   sortPrograms(sortValue) {
//     this.setState(
//       {
//         sortValue: sortValue
//       },
//       this.searchPrograms
//     )
//   },

//   submitButton() {
//     if (this.props.submit_button) {
//       return <button className="btn btn-primary pull-right">Submit</button>
//     }
//   },

//   render() {
//     return (
//       <div>
//         <h4 className="sub-title" style={{ display: "inline-block" }}>
//           Programs
//         </h4>
//         {this.submitButton()}

//         <div className="row">
//           <QueueTemplate.SelectedPrograms data={this.state.selectedPrograms} />
//         </div>

//         <h4 className="sub-title" style={{ display: "inline-block" }}>
//           Available Programs
//         </h4>
//         <div
//           className="form-inline form-search pull-right"
//           id="programs_search"
//         >
//           <input
//             placeholder="Search"
//             value={this.state.searchValue}
//             onChange={this.updateSearchValue}
//             onKeyPress={this.onKeyPress}
//             className="form-control"
//             type="search"
//           />
//           <a onClick={this.handleSearch} className="btn btn-primary">
//             Search
//           </a>
//           {this.state.showReset ? (
//             <a onClick={this.handleReset} className="btn btn-danger">
//               Reset
//             </a>
//           ) : null}
//         </div>

//         <div className="row">
//           <QueueTemplate.AvailablePrograms
//             onSortLinkClick={this.sortPrograms}
//             sortValue={this.state.sortValue}
//             data={this.state.availablePrograms}
//           />
//         </div>

//         <RemovedProgramsList data={this.state.removedPrograms} />
//       </div>
//     )
//   }
// })
