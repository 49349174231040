import React from "react"
import _ from "lodash"
import RailsForm from "../form/RailsForm"
import { String } from "../form/inputs"
import Questionnaire from "./Questionnaire"
import Question from "./Question"

const BLANK_QUESTION = { type: "text", options: [""] }

const ActivityReflection = props => {
  const [name, setName] = React.useState(props.activityReflection.name || "")
  const [questions, setQuestions] = React.useState(
    props.activityReflection.questions
  )
  const [errors, setErrors] = React.useState({})

  React.useEffect(() => {
    if (questions && questions.length === 0) {
      setQuestions([...questions, BLANK_QUESTION])
    }
  }, [])

  const onNameChange = e => setName(e.target.value)

  const addQuestion = e => {
    e.preventDefault()
    setQuestions([...questions, BLANK_QUESTION])
  }

  const updateQuestion = (index, question) => {
    const result = Array.from(questions || [])
    result[index] = question
    setQuestions(result)
  }

  const renderQuestions = questions.map((question, index) => (
    <Question
      key={"question" + index}
      question={question}
      namePrefix="activity_reflection[questions][]"
      index={index}
      updateQuestion={updateQuestion}
    />
  ))

  return (
    <div>
      <div className="col-md-6 form">
        <h2 className="bottom_space">Create a Reflection</h2>
        <div className="well">
          <RailsForm action={props.action} method={props.method}>
            <String
              label="Name"
              error={!!errors["name"]}
              value={name || ""}
              onChange={onNameChange}
              name="activity_reflection[name]"
            />

            {renderQuestions}

            <div>
              <a href="#" onClick={addQuestion} className="btn btn-link">
                + Add question
              </a>
            </div>

            <button className="btn btn-primary">Submit</button>
          </RailsForm>
        </div>
      </div>

      <div className="col-md-6">
        <h2 className="bottom_space">Preview</h2>
        <div className="well">
          <Questionnaire questions={questions} isPreview={true} />
        </div>
      </div>
    </div>
  )
}

export default ActivityReflection
