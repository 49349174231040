import React from "react"

const ActivityMetadataRow = props => {
  const htmlIdFor = fieldName => `metadata_${fieldName}_${props.index}`

  return (
    <div className="row">
      <div className="col-xs-5">
        <div className="form-group string required">
          <label
            className="string required control-label"
            htmlFor={htmlIdFor("key")}
          >
            <abbr title="required">*</abbr> Key
          </label>
          <input
            name="activity[metadata_array][][key]"
            id={htmlIdFor("key")}
            className="string required form-control"
            value={props.data.key}
            onChange={e => props.onKeyChange(e.target.value)}
          />
        </div>
      </div>

      <div className="col-xs-6">
        <div className="form-group string required">
          <label
            className="string required control-label"
            htmlFor={htmlIdFor("value")}
          >
            <abbr title="required">*</abbr> Value
          </label>
          <input
            name="activity[metadata_array][][value]"
            id={htmlIdFor("value")}
            className="string required form-control"
            value={props.data.value}
            onChange={e => props.onValueChange(e.target.value)}
          />
        </div>
      </div>

      <div className="col-xs-1">
        <div className="form-group string">
          <div className="activity-metadata-controls">
            <a href="#" id={htmlIdFor("delete")} onClick={props.onDelete}>
              <i className="fa fa-trash" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActivityMetadataRow
