import React from "react";

const StudentProgramsStatusFilter = (props) => {
  const DEFAULT_STYLE = "btn btn-default btn-block text-center";
  const SELECTED_STYLE = "btn btn-primary btn-block text-center";
  let activeStyle, completedStyle;

  if (props.completedStatusTab) {
    activeStyle = DEFAULT_STYLE;
    completedStyle = SELECTED_STYLE;
  } else {
    activeStyle = SELECTED_STYLE;
    completedStyle = DEFAULT_STYLE;
  }

  return (
    <>
      <div className="col-md-3 grid_space">
        <button
          type="button"
          className={activeStyle}
          onClick={() => props.setCompletedStatusTab(false)}
        >
          Upcoming
        </button>
      </div>
      <div className="col-md-3 grid_space">
        <button
          type="button"
          className={completedStyle}
          onClick={() => props.setCompletedStatusTab(true)}
        >
          Completed
        </button>
      </div>
      </>
  );
};

export default StudentProgramsStatusFilter;
