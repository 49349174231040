import React from "react"

const AvailableProgramRow = props => {
  const addProgram = e => {
    e.preventDefault()
    props.selectProgram(props.data)
  }

  return (
    <tr>
      <td>
        <a
          href="#"
          title="Add program"
          onClick={addProgram}
          className="btn btn-primary btn-xs small_right_space"
          id={"add_program_" + props.data.id + "_to_queue_template"}
        >
          <span className="fa fa-plus" aria-hidden="true" />
        </a>
      </td>

      <td>{props.data.name}</td>

      <td>{props.data.description}</td>

      {!props.hideSchools && (<td>
        <ul>
          {props.data.schools.map((schoolName, index) => (
            <li key={index}>{schoolName}</li>
          ))}
        </ul>
      </td>)}
    </tr>
  )
}

export default AvailableProgramRow
