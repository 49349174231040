import React from "react"
import PropTypes from "prop-types"

const StudentProgramsItemActivities = props => {
  let activities = props.activities
    .sort((a, b) => {
      let activityA = a.activity.toLowerCase()
      let activityB = b.activity.toLowerCase()

      if (activityA > activityB) {
        return 1
      } else if (activityB < activityA) {
        return -1
      }

      return 0
    })
    .map((activity, index) => <li key={"activity_" + index}>{activity}</li>)

  return (
    <tr>
      <td colSpan="4">
        <ul>{activities}</ul>
      </td>
    </tr>
  )
}

StudentProgramsItemActivities.propTypes = {
  activities: PropTypes.array.isRequired
}

export default StudentProgramsItemActivities
